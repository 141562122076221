<template>
	<el-dialog
		v-model="visible"
		width="600px"
		class="container"
		title="合同信息导入"
		:before-close="onClose"
		:close-on-click-modal="false"
		append-to-body
		destroy-on-close
	>
		<el-upload
			v-model:file-list="fileList"
			ref="uploadRef"
			:auto-upload="false"
			:on-change="fileChange"
			:on-exceed="onExceed"
		>
			<el-button>请选择上传文件</el-button>
		</el-upload>
		<div style="margin-top: 30px">
			<span>合同信息导入模板：</span>
			<span class="click" @click="onDownload">合同信息导入模板.xlsx</span>
		</div>
		<template #footer>
			<div class="dialog-footer">
				<el-button type="primary" @click="onSubmit" :loading="loading">确定</el-button>
				<el-button @click="onClose">取消</el-button>
			</div>
		</template>
	</el-dialog>
</template>

<script setup>
import { defineExpose, defineEmits, h, inject, ref } from 'vue'
import { ElMessageBox } from 'element-plus'
import { useModal } from '@/utils/hooks.js'
import { importOrder } from '@/api/driver.js'

const emit = defineEmits(['on-refresh'])
const fileList = ref([])
const $message = inject('$message')
const uploadRef = ref(null)
const { visible, setVisible } = useModal()
const loading = ref(false)

function onDownload() {
	window.open(
		'https://oss-prod-vsc-master.renrenjia.com.cn/000000/renrenjia/image/20231204/hb2d74t7/ftls2vur88.xlsx',
		'_blank',
	)
}

function open() {
	setVisible(true)
}

function onClose() {
	setVisible(false)
	fileList.value = []
}

function onExceed() {
	$message.error('只能上传一个文件')
}

async function fileChange(file) {
	fileList.value = [file]
}
async function onSubmit() {
	const file = fileList.value[0]
	if (!file) return $message.error('请上传合同信息')
	const formData = new FormData()
	formData.append('file', file.raw, file.name)
	loading.value = true
	try {
		await importOrder(formData)
		loading.value = false
		$message.success('导入成功')
		emit('on-refresh')
		onClose()
	} catch (e) {
		loading.value = false
		ElMessageBox({
			title: '导入失败提示',
			message: h('p', { style: 'white-space: pre-line;' }, e.message),
		})
	}
}

defineExpose({ open })
</script>

<style lang="less" scoped>
.click {
	cursor: pointer;
	color: #409eff;
}
</style>
