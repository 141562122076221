<template>
	<div class="container">
		<label-item title="基础信息" :list="basicData">
			<template #subTitle>
				<span v-if="_.data.econtractUrl" style="margin-left: 20px; font-size: 14px">
					《车辆租赁合同》
					<el-link type="primary" @click="openDocument(_.data.econtractUrl)">查看</el-link>
				</span>
			</template>
		</label-item>
		<label-item title="商品信息" :list="productData" />
		<label-item title="合同备注" v-if="_.data.contractRemark" />
		<p class="remark">{{ _.data.contractRemark }}</p>
		<label-item title="政策说明" v-if="_.data.welfarePolicy" />
		<p class="remark">{{ _.data.welfarePolicy }}</p>
		<label-item title="合同文件" v-if="filesDataComp.length" />
		<el-row justify="start" class="contract-files">
			<div v-for="(item, index) in filesDataComp" :key="index">
				<el-image
					v-if="item.isImg"
					:src="item.url"
					:preview-src-list="srcList"
					@click="onPreviewFile(item.url)"
					class="file-style"
				/>
				<el-tooltip
					v-else
					class="item"
					effect="dark"
					placement="bottom"
					:content="fileNameFilter(item.url)"
				>
					<div class="file-info">
						<el-image
							:src="getFileTypeDefaultImage(item.url, item.url)"
							@click="openDocument(item.url)"
							class="file-style"
						/>
					</div>
				</el-tooltip>
			</div>
		</el-row>
	</div>
</template>

<script setup>
import { ref, defineProps, computed } from 'vue'
import labelItem from './label'
import { getFileTypeDefaultImage } from '@/components/YiFileUploader/utils'

const _ = defineProps({
	data: {
		type: Object,
		default: () => ({}),
	},
})

const srcList = ref([])

const basicData = computed(() => [
	{ label: '合同ID', value: _.data.orderNo },
	{ label: '车牌号', value: _.data.carNumber },
	{ label: '合同起租日', value: _.data.contractStartDate },
	{ label: '合同到期日', value: _.data.contractEndDate },
])
const productData = computed(() => {
	let extraSecurityDepositStr = ''
	if ([-1, 1, 2].includes(Number(_.data.status)) && _.data.securityDeposit) {
		extraSecurityDepositStr = `（已收 ${_.data.receivedDepositAmount || 0}）`
	}
	let sourceStr = ''
	if ([1, 3].includes(Number(_.data.orderSource)) && _.data.recommendMobile) {
		sourceStr = `（${_.data.recommendMobile}）`
	} else if ([2].includes(Number(_.data.orderSource)) && _.data.orderSourceChannelName) {
		sourceStr = `（${_.data.orderSourceChannelName}）`
	} else if ([5].includes(Number(_.data.orderSource)) && _.data.internetPlatformName) {
		sourceStr = `（${_.data.internetPlatformName}）`
	} else if ([4].includes(Number(_.data.orderSource)) && _.data.salesmanName) {
		sourceStr = `（${_.data.salesmanName}）`
	}
	return [
		{ label: '订单来源', value: _.data.orderSourceName + sourceStr },
		{ label: '商品方案', value: _.data.leasingSchemeName },
		{ label: '平台渠道', value: _.data.platformChannel },
		{ label: '月租', value: _.data.monthlyRent },
		{ label: '还款日', value: _.data.dueDate },
		{ label: '期数', value: _.data.contractPeriod },
		{ label: '押金', value: _.data.securityDeposit + extraSecurityDepositStr },
		{ label: '押金期数', value: _.data.depositPeriod },
	]
})
const filesDataComp = computed(() => {
	return (_.data.contractAnnexesArr || []).map(item => {
		let url = item
		let suffix = item.split('.').pop().toLocaleUpperCase()
		let isImg = ['JPG', 'JPEG', 'PNG'].includes(suffix)
		isImg && srcList.value.push(item)
		return {
			url,
			isImg,
		}
	})
})
const fileNameFilter = file => {
	try {
		const fileSuffixArr = file.split('/')
		const fileName = fileSuffixArr[fileSuffixArr.length - 1].replace(/&/g, '')
		return fileName
	} catch (e) {
		return '暂无文件名信息!'
	}
}
const onPreviewFile = () => {
	srcList.value = []
}
const openDocument = url => {
	window.open(url, '_blank')
}
</script>

<style lang="less" scoped>
.remark {
	color: #292929;
	font-size: 14px;
}
.contract-files {
	margin-top: 10px;
}
.file-style {
	height: 100px;
	width: 100px;
	margin-right: 5px;
	cursor: pointer;
}
</style>
<style>
.el-overlay {
	z-index: 2014 !important;
}
</style>
