<template>
	<el-dialog
		v-model="visible"
		width="560px"
		class="container"
		title="合同操作"
		:before-close="onClose"
		:close-on-click-modal="false"
		append-to-body
		destroy-on-close
	>
		<div v-loading="isLoading">
			<p>
				<span>合同ID：</span>
				<span>{{ model.orderNo }}</span>
			</p>
			<p>
				<span>车牌号：</span>
				<span>{{ model.carNumber }}</span>
			</p>
			<p>
				<span>合同状态：</span>
				<span>{{ mapDict('order_status', model.status) }}</span>
			</p>
			<p>
				<span>合同时间：</span>
				<span>
					{{ model.contractStartDate ? dayjs(model.contractStartDate).format('YYYY-MM-DD') : '--' }}
					~
					{{ model.contractEndDate ? dayjs(model.contractEndDate).format('YYYY-MM-DD') : '--' }}
				</span>
			</p>
			<p>
				<span>合同金额：</span>
				<span>{{ model.monthlyRent }}元/月</span>
			</p>
			<p>
				<span>司机名称：</span>
				<span>{{ model.driverName }}</span>
			</p>

			<el-form
				:model="formData"
				:rules="rules"
				ref="formRef"
				label-width="120"
				style="width: 500px"
			>
				<el-form-item label="合同结束" prop="terminateType">
					<el-radio-group v-model="formData.terminateType" @change="onRadioChange" class="ml-4">
						<!-- <el-radio :label="1" size="large">预约还车</el-radio> -->
						<el-radio :label="2" size="large">立即结束</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="还车时间" prop="reserveDate" v-if="formData.terminateType == 1">
					<el-date-picker
						style="width: 280px"
						placeholder="请选择还车时间"
						type="date"
						size="default"
						:disabled-date="disabledDate"
						v-model="formData.reserveDate"
					>
						<template #default="cell">
							<div
								:class="{
									disabled: cell.disabled || checkCount(cell),
									'is-current': cell.isCurrent,
								}"
								@click="e => onDateChange(e, cell)"
							>
								<span style="font-size: 16px; font-weight: 600">
									{{ cell.text }}
								</span>
								<p class="count">{{ mapCalendar(cell) }}</p>
							</div>
						</template>
					</el-date-picker>
				</el-form-item>
				<p v-if="formData.terminateType == 1 && model.reserveDate">
					当前车辆已预约：{{ model.reserveDate }} 还车，提交后将修改原有还车时间
				</p>
				<el-form-item
					label="退车类型"
					prop="terminateContractType"
					v-if="formData.terminateType == 2 && hasStockIn"
				>
					<el-select v-model="formData.terminateContractType" placeholder="请选择退车类型">
						<el-option
							v-for="item in options"
							:key="item.value"
							:label="item.label"
							:value="item.value"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="验车单" prop="contractEndDate" v-if="formData.terminateType == 2">
					<span v-if="!hasStockIn">该车辆近15天无验车单，请先完成验车再操作</span>
					<span v-else>
						{{ stockInData.carInspectTime }} 质损金额：{{ stockInData.damageAmount }}，验车人：{{
							stockInData.carInspectUser
						}}
					</span>
				</el-form-item>
			</el-form>
		</div>

		<template #footer>
			<div class="dialog-footer">
				<el-button
					type="primary"
					@click="onSubmit"
					:disabled="formData.terminateType == 2 && !hasStockIn"
				>
					确定
				</el-button>
				<el-button @click="onClose">取消</el-button>
			</div>
		</template>
	</el-dialog>
</template>

<script setup>
import dayjs from 'dayjs'
import { defineExpose, inject, defineEmits, ref, watch } from 'vue'
import { useState, useModal, useFetch, useDict } from '@/utils/hooks.js'
import { createReserve, getDetailByCarId, reserveCalendar, checkStockIn } from '@/api/driver.js'

const $message = inject('$message')
const emit = defineEmits(['on-refresh', 'on-open'])
const { visible, setVisible } = useModal()
const [model, setModel] = useState({})
const [formData, setForm] = useState({ terminateType: 2 })
const [dict, setDict] = useState({})
const [calendar, setCalendar] = useState([])
const [hasStockIn, setHasStockIn] = useState(false)
const [stockInData, setStockInData] = useState({})
const [options, setOptions] = useState([])
const { isLoading, fetchData } = useFetch()

const formRef = ref(null)

watch(
	() => formData.value.isPaid,
	val => {
		if (val) {
			const rent = model.value.monthlyRent || 0
			const day = formData.value.delayDays || 0
			formData.value.amount = ((rent / 30) * day).toFixed(2)
		} else {
			formData.value.amount = 0
		}
	},
)
watch(
	() => formData.value.delayDays,
	val => {
		if (val) {
			formData.value.amount = ((model.value.monthlyRent / 30) * val).toFixed(2)
		} else {
			formData.value.amount = 0
		}
	},
)

const rules = {
	reserveDate: [{ required: true, message: '请选择还车时间', trigger: 'blur' }],
	terminateContractType: [{ required: true, message: '请选择退车类型', trigger: 'blur' }],
}

function open(data) {
	getCalendar()
	setModel(data)
	getDate(data.carId)
	fetchDict()
	onRadioChange(2)
	setVisible(true)
}
function disabledDate(time) {
	return time.getTime() > dayjs().add(30, 'day') || time.getTime() <= dayjs().subtract(1, 'day')
}
function checkCount(cell) {
	const target = calendar.value.find(
		item => item.reserveDate === dayjs(cell.date).format('YYYY-MM-DD'),
	)
	return target ? target.reserveNum > 100 : false
}
function onDateChange(e, cell) {
	console.log('cell', cell)
	if (checkCount(cell)) e.stopPropagation()
}
function mapCalendar(data) {
	const target = calendar.value.find(
		item => item.reserveDate === dayjs(data.date).format('YYYY-MM-DD'),
	)
	return target ? target.reserveNum + '单' : ''
}
async function getDate(carId) {
	const { err, data } = await fetchData(getDetailByCarId, carId)
	if (err) return
	console.log('预约信息', data)
	setForm({ ...formData.value, ...data, reserveDate: undefined })
}

function onClose() {
	setVisible(false)
	setForm({ terminateType: 2 })
}
function mapDict(key, value) {
	const e = dict.value[key]
	if (!e) return '--'
	const target = e.find(item => item.code == value)

	return target ? target.name : '--'
}
async function fetchDict() {
	const dict = await useDict(['terminate_contract_type', 'order_status'])
	const options = dict.terminate_contract_type.map(item => ({
		label: item.name,
		value: item.code * 1,
	}))
	setOptions(options)
	if (dict) setDict(dict)
}
async function getCalendar() {
	const { err, data } = await fetchData(reserveCalendar, {})
	if (err) {
		$message.error(err.message)
	} else {
		setCalendar(data)
	}
}
function onRadioChange(val) {
	if (val == 2) {
		checkExist()
		const { contractEndDate } = model.value
		const notReach = dayjs().isBefore(dayjs(contractEndDate))
		if (notReach) formData.value.terminateContractType = 2
		else formData.value.terminateContractType = 3
	}
}
async function checkExist() {
	const { carId } = model.value
	const { err, data } = await checkStockIn(carId)
	if (err) return
	console.log('是否有验车单', data)
	setHasStockIn(!!data)
	if (data) {
		setStockInData(data)
	}
}
async function onSubmit() {
	const { terminateType } = formData.value
	if (terminateType == 2) {
		await formRef.value.validate()
		const { carStatus } = model.value
		if (carStatus !== 6) return $message.error('当前车辆状态不是待退车，不允许退车结束')
		emit('on-open', model.value.orderId, {
			...stockInData.value,
			terminateContractType: formData.value.terminateContractType,
		})
		onClose()
		return
	}
	await formRef.value.validate()

	const { carId } = model.value
	const { reserveDate: raw } = formData.value
	const reserveDate = dayjs(raw).format('YYYY-MM-DD') + ' 23:59:59'
	console.log('model', model.value)
	const params = { carId, reserveDate }
	const { err } = await fetchData(createReserve, params)
	if (err) return $message.error(err.message)
	$message.success('操作成功')
	emit('on-refresh')
	onClose()
}

defineExpose({ open, onClose })
</script>

<style lang="less" scoped>
.title {
	border-left: 3px solid #2e3443;
	color: #2e3443;
	padding-left: 4px;
	font-size: 16px;
	font-weight: 500;
}
.disabled {
	background: #f5f7fa;
	color: #a8abb2;
}
.count {
	margin: 0;
	height: 12px;
	line-height: 12px;
	font-size: 11px;
}
.is-current {
	color: #409eff;
}
.disabled:hover {
	cursor: not-allowed;
}
</style>
