<template>
	<el-dialog
		v-model="visible"
		width="700px"
		class="container"
		title="合同续签"
		:before-close="onClose"
		:close-on-click-modal="false"
		append-to-body
		destroy-on-close
	>
		<div v-loading="isLoading">
			<yi-form
				v-if="visible"
				:model="model"
				:rules="rules"
				ref="formRef"
				label-width="120px"
				size="small"
				:form-list="formList"
			>
				<template #contractEndDate>
					<el-date-picker
						style="width: 190px"
						placeholder="请选择合同结束日期"
						type="date"
						size="default"
						disabled
						v-model="model.contractEndDate"
					/>
				</template>
			</yi-form>
			<div style="margin-left: 50px">
				<p class="label" v-if="policyText">政策说明</p>
				<p>{{ policyText }}</p>
			</div>
			<div style="margin-left: 50px">
				<p class="title">
					合同文件
					<span>({{ model.contractAnnexesArr ? model.contractAnnexesArr.length : 0 }}/5)</span>
				</p>
				<yi-file-uploader
					v-model="model.contractAnnexesArr"
					type="file"
					source-type="image"
					product-type="renrenjia"
					accept=".jpg,.jpeg,.png,.xlsx,.xls,.pdf,.zip,.rar,.pptx,.docx"
					:max="5"
				/>
			</div>
		</div>

		<template #footer>
			<div class="dialog-footer">
				<el-button type="primary" @click="onSubmit">确定</el-button>
				<el-button @click="onClose">取消</el-button>
			</div>
		</template>
	</el-dialog>
</template>

<script setup>
import dayjs from 'dayjs'
import { defineExpose, inject, defineEmits, ref, watch } from 'vue'
import { useState, useModal, useFetch, useDict, useSelect } from '@/utils/hooks.js'
import { orderRenewal, getChannelById, getChannelByCar } from '@/api/driver.js'
import { productList } from '@/api/product'
import { renewalForm } from './config'

const $message = inject('$message')
const emit = defineEmits(['on-refresh'])
const { visible, setVisible } = useModal()
const [model, setModel] = useState({})
const [dicts, setDicts] = useState({})
const { isLoading, fetchData } = useFetch()
const [products, setProduct] = useState([])
const [policyText, setPolicy] = useState('')

const formList = ref(renewalForm)
const formRef = ref(null)
const rules = {
	contractEndDate: [{ required: true, message: '请选择合同结束时间', trigger: 'blur' }],
	renewalRemark: [{ required: true, message: '请填写续签备注', trigger: 'blur' }],
}

watch(
	() => model.value.contractPeriod,
	async val => {
		if (val) {
			const { contractStartDate } = model.value
			const month = typeof val === 'object' ? val.value : val
			model.value.contractEndDate = dayjs(contractStartDate)
				.add(month, 'month')
				.subtract(1, 'day')
				.format('YYYY-MM-DD')
			if (!model.value.useContractProduct) return
			const contractPeriod = findItem('contractPeriod')
			const target = contractPeriod.options.find(item => item.value == val)
			const { id, monthlyAmount, companyWelfarePolicy, platformWelfarePolicy, giftDays } = target
			console.log('target', target)
			model.value.monthlyRent = monthlyAmount

			model.value.leasingSchemeId = id
			const day = giftDays ? `期满赠送${giftDays}天` : ''
			const policy1 = giftDays
				? `${platformWelfarePolicy ? '，' + platformWelfarePolicy : ''}`
				: platformWelfarePolicy || ''
			const policy2 = policy1
				? `，${companyWelfarePolicy.map(txt => txt).join('，')}`
				: companyWelfarePolicy.map(txt => txt).join('，')
			const text = day + policy1 + policy2
			setPolicy(text)
		}
	},
)
watch(
	() => model.value.goodsLeaseId,
	(val, oldVal) => {
		const target = products.value.find(item => item.value == val)
		const { leaseSchemeList } = target || {}
		if (val && oldVal) {
			model.value.contractPeriod = ''
			setPolicy('')
		}
		if (leaseSchemeList) {
			const contractPeriod = findItem('contractPeriod')
			const list = genList(leaseSchemeList, 'periodsNum')
			contractPeriod.options = list.sort((a, b) => a.value - b.value)
			if (contractPeriod.options.length === 1) {
				model.value.contractPeriod = contractPeriod.options[0].value
			}
		}
	},
)
watch(
	() => model.value.platformChannelId,
	(val, oldVal) => {
		fetchProduct()
		if (val && val !== oldVal) {
			model.value.goodsLeaseId = ''
			model.value.contractPeriod = ''
			model.value.monthlyRent = ''
			model.value.contractEndDate = ''
			const contractPeriod = findItem('contractPeriod')
			contractPeriod.options = []
			setPolicy('')
		}
	},
)
watch(
	() => model.value.platformChannelId,
	(val, oldVal) => {
		fetchProduct()
		if (val && val !== oldVal) {
			model.value.goodsLeaseId = ''
			model.value.contractPeriod = ''
			model.value.monthlyRent = ''
			model.value.contractEndDate = ''
			clearOptions()
			setPolicy('')
		}
	},
)

function open(data) {
	const { contractEndDate } = data
	fetchDict()
	setVisible(true)
	getChannel(data)
	console.log('待续签合同', data)
	const { orderCustomerType, useContractProduct } = data
	const driverName = findItem('driverName')
	driverName.label = orderCustomerType === 2 ? '公司名称' : '司机姓名'
	const contractStartDate = dayjs(contractEndDate).add(1, 'day').format('YYYY-MM-DD')
	const goodsLeaseId = findItem('goodsLeaseId')
	const leasingSchemeName = findItem('leasingSchemeName')
	const periodSelect = formList.value.find(
		item => item.prop === 'contractPeriod' && item.component == 'el-select',
	)
	const periodInput = formList.value.find(
		item => item.prop === 'contractPeriod' && item.component == 'el-input',
	)
	periodSelect.hide = !useContractProduct
	goodsLeaseId.hide = !useContractProduct
	leasingSchemeName.hide = useContractProduct
	periodInput.hide = useContractProduct
	setModel({ ...data, contractStartDate, contractEndDate: '', contractPeriod: '' })
	// fetchProduct()
	fetchChannel(data.carId)
}
async function fetchChannel(id) {
	const fn = () => getChannelByCar(id)
	const { data } = await useSelect(fn)
	const platformChannel = findItem('platformChannelId')
	platformChannel.options = data
	if (data.length === 1) {
		model.value.platformChannelId = data[0].value
		fetchProduct()
	}
}
async function fetchProduct() {
	const payload = { leaseSchemeStatus: [1], leaseSchemeType: 2 }
	const { platformChannelId, carModelId, companyId } = model.value
	if (platformChannelId) payload.platformChannelIds = [platformChannelId]
	if (carModelId) payload.carModelIds = [carModelId]
	if (companyId) payload.companyIds = [companyId]
	const { data, err } = await fetchData(productList, payload)
	if (!err) console.log('商品方案列表', data)

	const { records } = data
	const goodsLeaseId = findItem('goodsLeaseId')
	const list = records.map(item => ({
		...item,
		label: item.leaseSchemeName,
		value: item.id,
	}))
	goodsLeaseId.options = list
	setProduct(list)
	if (records && records.length == 1) {
		model.value.goodsLeaseId = records[0].id
	}
}

async function getChannel(detail) {
	const { platformChannelId } = detail
	const { data } = await getChannelById(platformChannelId)
	const { billPeriodType, billGenerateType } = data || {}
	setModel({
		...model.value,
		monthlyRentGenerateType: billGenerateType,
		monthlyRentPeriodType: billPeriodType,
	})
	const generateType = findItem('monthlyRentGenerateType')
	const dueDate = findItem('dueDate')
	generateType.options = dicts.value.bill_generate_type.map(item => ({
		label: item.name,
		value: item.code * 1,
	}))
	generateType.attrs.disabled = true
	model.value.dueDate = billPeriodType * 1 === 1 ? 31 : model.value.dueDate
	dueDate.attrs.disabled = billPeriodType * 1 === 1
}

function onClose() {
	setPolicy('')
	setVisible(false)
	setModel({})
	clearOptions()
}

function findItem(key) {
	return formList.value.find(item => item.prop === key)
}

async function fetchDict() {
	const dict = await useDict(['bill_generate_type', 'contract_period', 'order_source'])
	setDicts(dict)
	const generateType = findItem('monthlyRentGenerateType')
	generateType.options = dict.bill_generate_type.map(item => ({
		label: item.name,
		value: item.code * 1,
	}))
	const orderSource = findItem('orderSource')
	orderSource.options = dict.order_source.map(item => ({
		label: item.name,
		value: item.code * 1,
	}))
}
async function onSubmit() {
	await formRef.value.validate()
	const { contractEndDate, contractStartDate, contractPeriod, ...rest } = model.value
	const payload = { ...rest }
	payload.contractStartDate = dayjs(contractStartDate).format('YYYY-MM-DD') + ' 00:00:00'
	payload.contractEndDate = dayjs(contractEndDate).format('YYYY-MM-DD') + ' 23:59:59'
	console.log('submit', contractPeriod, typeof contractPeriod)
	if (typeof contractPeriod === 'object') {
		payload.contractPeriod = contractPeriod.value
	} else {
		payload.contractPeriod = contractPeriod
	}
	const { err } = await fetchData(orderRenewal, payload)
	if (err) return $message.error(err.message)
	$message.success('合同续签提交成功')
	emit('on-refresh')
	onClose()
}
function genList(list, key) {
	return list.reduce((acc, cur) => {
		const { monthlyAmount, cashPledgeAmount } = cur
		const label = `${cur[key]}期(月租：${monthlyAmount}元，押金：${cashPledgeAmount}元)`
		const res = { ...cur, label, value: { value: cur[key], id: cur.id } }
		acc.push(res)
		return acc
	}, [])
}
function clearOptions() {
	const contractPeriod = findItem('contractPeriod')
	contractPeriod.options = []
}
defineExpose({ open })
</script>

<style lang="less" scoped>
.title {
	border-left: 3px solid #2e3443;
	color: #2e3443;
	padding-left: 4px;
	font-size: 16px;
	font-weight: 500;
}
.label {
	color: #606266;
	font-size: 12px;
	font-weight: 600;
}
</style>
