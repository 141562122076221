<template>
	<div class="container">
		<yi-list-layout :loading="isLoading">
			<template #title>
				<div class="header-title">合同管理</div>
				<div class="header-button">
					<el-button
						v-permission="'pc/driver-center/contract-manage/save'"
						type="success"
						@click="onCreate"
					>
						<el-icon class="icon-plus"><Plus /></el-icon>
						新增
					</el-button>

					<el-button
						type="primary"
						v-permission="'pc/driver-center/contract-manage/edit-driver-service'"
						@click="onModify"
					>
						修改司服伙伴
					</el-button>
					<el-button v-permission="'pc/driver-center/contract-manage/import'" @click="onImport">
						Excel导入
					</el-button>
					<el-button
						:loading="loading"
						@click="onExport"
						v-permission="'pc/driver-center/contract-manage/export'"
					>
						导出Excel
					</el-button>
				</div>
			</template>
			<template #filter>
				<yi-filter ref="filterRef" :setting="settingRef" @change="onChangeFilter">
					<template #licensePlateNums="{ filterParams }">
						<carNum :filterParams="filterParams" />
					</template>
				</yi-filter>
				<div class="button-list">
					<el-button
						v-for="(button, index) in statusList"
						:key="button.key"
						:class="selectedIndex === index ? 'selected-button' : ''"
						@click="onStatusChange(index, button)"
					>
						{{ button.title }}({{ count[button.key] }})
					</el-button>
				</div>
			</template>

			<template #table>
				<yi-table
					ref="yiTableRef"
					table-height="100%"
					:columns="columns"
					:data="tableData"
					showSelection
					:params="{ page }"
					autoWidth
					@selection-change="onSelectionChange"
				>
					<template #orderNo="{ value, row }">
						<el-link type="primary" :underline="false" @click="onDetail(row)">
							{{ value }}
						</el-link>
					</template>
					<template #status="{ value }">
						<span>{{ mapDict('order_status', value) }}</span>
					</template>
					<template #orderSource="{ value }">
						<span>{{ mapDict('order_source', value) }}</span>
					</template>
					<template #contractType="{ value }">
						<span>{{ mapDict('contract_type', value) }}</span>
					</template>
					<template #contractAnnexesArr="{ value }">
						<span v-if="value && value.length" class="cursor" @click="onView(value)">查看</span>
						<span v-else>--</span>
					</template>
					<template #operation="{ value, row }">
						<el-row v-if="[-2].includes(row.status)" type="flex" justify="space-between">
							<span
								v-permission="'pc/driver-center/contract-manage/sign'"
								class="cursor"
								@click="onSigning(row)"
							>
								签约
							</span>
						</el-row>
						<div v-else style="display: flex; justify-content: space-between">
							<span
								v-permission="'pc/driver-center/contract-manage/edit'"
								v-if="[1, 2, 5].includes(selectedIndex) || [-1, 1, 4].includes(row.status)"
								class="cursor"
								@click="onEdit(row)"
							>
								编辑
							</span>
							<span
								class="cursor"
								v-permission="'pc/driver-center/contract-manage/end'"
								v-if="[2, 5].includes(selectedIndex) || [1, 4].includes(row.status)"
								@click="onTerminate(row)"
							>
								结束
							</span>
							<span
								class="cursor"
								v-permission="'pc/driver-center/contract-manage/renewal'"
								v-if="[2, 5].includes(selectedIndex) || [1, 4].includes(row.status)"
								@click="onRenewal(row)"
							>
								续签
							</span>
							<span
								class="cursor"
								v-permission="'pc/driver-center/contract-manage/delay'"
								v-if="[2, 5].includes(selectedIndex) || [1, 4].includes(row.status)"
								@click="onDelay(row)"
							>
								延期
							</span>
							<span
								class="cursor"
								v-permission="'pc/driver-center/contract-manage/replace-car'"
								v-if="[2].includes(selectedIndex) || [1].includes(row.status)"
								@click="onChangeCar(row)"
							>
								换车
							</span>
						</div>
					</template>
				</yi-table>
			</template>
			<template #footer>
				<yi-pagination
					:current-page="page.current"
					:page-size="page.size"
					:total-count="totalCount"
					@page-change="onPageChange"
					@size-change="onSizeChange"
				/>
			</template>
		</yi-list-layout>
		<el-image-viewer
			v-if="visible"
			:url-list="srcList"
			hide-on-click-modal
			@close="setVisible(false)"
		/>
		<settle-form ref="settleRef" @on-refresh="closeAndRefresh" @on-detail="onStockInDetail" />
		<driver-form ref="formRef" @on-refresh="fetchList" />
		<StockInDetail ref="stockInDetail" />
		<terminate-form ref="terminateRef" @on-refresh="fetchList" @on-open="onSettle" />
		<delay-form ref="delayRef" @on-refresh="fetchList" />
		<renewal-form ref="renewalRef" @on-refresh="fetchList" />
		<import-form ref="importRef" @on-refresh="fetchList" />
		<modify-form ref="ModifyRef" @on-refresh="fetchList" />
		<change-form ref="changeRef" @on-refresh="fetchList" />
		<contract-detail ref="detailRef" @on-refresh="fetchList" />
		<contract-confirm ref="confirmRef" />
	</div>
</template>

<script>
import { defineComponent } from 'vue'
import carNum from '@/components/YiFilter/carNum'
export default defineComponent({
	name: 'DriverContract',
})
</script>

<script setup name="DriverContract">
import { inject, onActivated, ref, getCurrentInstance, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { setting, columns } from './config'
import { useState, useFetch, useDict, useFilter } from '@/utils/hooks.js'
import { companySelect, channelSelect, maintenanceSelect } from '@/api/vehicle.js'
import { orderList, exportOrder, orderSourceSelect, orderStatistics } from '@/api/driver.js'
import { handleExport } from '@/utils/util'
import { getStore } from '@/utils/store'

import DriverForm from './form'
import TerminateForm from './terminate'
import DelayForm from './delay'
import RenewalForm from './renewal'
import ImportForm from './import'
import ModifyForm from './modify'
import ChangeForm from './changeCar'
import ContractDetail from './detail'
import SettleForm from '../reserve/settle'
import StockInDetail from '../../vehicle/check/form'
import ContractConfirm from './confirm'

const $message = inject('$message')
const userInfo = getStore({ name: 'userInfo' })

const statusList = ref([
	{ title: '全部', key: 'allNum', status: null },
	{ title: '待履约', key: 'notActivatedNum', status: 1 },
	{ title: '履约中', key: 'inPerformanceNum', status: 2 },
	{ title: '待结算', key: 'waitSettlementNum', status: 4 },
	{ title: '结算中', key: 'settlementNum', status: 5 },
	{ title: '已结束', key: 'endedNum', status: 2 },
	{ title: '已归档', key: 'archivedNum', status: 6 },
	{ title: '已作废', key: 'invalidNum', status: 3 },
])

const formRef = ref(null)
const terminateRef = ref(null)
const delayRef = ref(null)
const renewalRef = ref(null)
const importRef = ref(null)
const ModifyRef = ref(null)
const changeRef = ref(null)
const detailRef = ref(null)
const settleRef = ref(null)
const stockInDetail = ref(null)
const confirmRef = ref(null)

const srcList = ref([])
const settingRef = ref(setting)

const [params, setParams] = useState({})
const [selection, setSelection] = useState([])
const [status, setStatus] = useState({ statusList: null })
const [totalCount, setTotal] = useState(0)
const [tableData, setTable] = useState([])
const [visible, setVisible] = useState(false)
const [loading, setLoading] = useState(false)
const { isLoading, fetchData } = useFetch()
const [dict, setDict] = useState({})
const [count, setCount] = useState({})
const [selectedIndex, setIndex] = useState(0)
const [page, setPage] = useState({ current: 1, size: 20 })

async function fetchList(refreshStatistics = true) {
	const payload = { ...params.value, page: page.value, ...status.value }
	console.log(4554, status.value)
	const service = orderList

	const { data, err } = await fetchData(service, payload)
	if (err) {
		$message.error(err.message)
	} else {
		if (refreshStatistics) fetchStatistics()
		setTable(data.records)
		setTotal(data.total)
	}
}
function closeAndRefresh() {
	fetchList()
	terminateRef.value.onClose()
}
async function fetchStatistics() {
	const payload = { ...params.value, page: page.value }
	const { data, err } = await fetchData(orderStatistics, payload)
	if (err) {
		$message.error(err.message)
	} else {
		setCount(data)
	}
}
function onStatusChange(index, button) {
	setIndex(index)
	if (button.status) {
		setStatus({ status: [button.status] })
	} else {
		setStatus({ status: null })
	}
	fetchList(false)
}
function onSettle(id, stockInData) {
	settleRef.value.open(id, stockInData)
}
function onStockInDetail(id) {
	stockInDetail.value.open(id, 'detail')
}
function onEdit(row) {
	formRef.value.open(row)
}
function onSelectionChange(val) {
	setSelection(val)
}
function onDetail(val) {
	detailRef.value.open(val.orderId)
}
function onView(list) {
	const images = list.filter(val => {
		const fileType = val && val.split('.').pop().toLocaleUpperCase()
		return ['BMP', 'JPG', 'JPEG', 'PNG', 'TIF', 'GIF'].includes(fileType)
	})
	const files = list.filter(val => {
		const fileType = val && val.split('.').pop().toLocaleUpperCase()
		return !['BMP', 'JPG', 'JPEG', 'PNG', 'TIF', 'GIF'].includes(fileType)
	})
	if (images && images.length) {
		setVisible(true)
		srcList.value = images
	}
	if (files && files.length) {
		files.forEach(url => {
			const iframe = document.createElement('iframe')
			iframe.style.display = 'none'
			iframe.style.height = 0
			iframe.src = url
			document.body.appendChild(iframe)
			setTimeout(() => {
				iframe.remove()
			}, 1000)
		})
	}
}
function mapDict(key, value) {
	const e = dict.value[key]
	if (!e) return '--'
	const target = e.find(item => item.code == value)

	return target ? target.name : '--'
}

async function onExport() {
	setLoading(true)
	const payload = Object.assign(params.value, { page: { ...page.value } }, status.value)
	try {
		const res = await exportOrder(payload)
		if (res) handleExport(res, '合同管理')
	} catch (err) {
		console.log('err', err)
		$message.error(err.msg || err.message)
	} finally {
		setLoading(false)
	}
}
async function fetchFilters() {
	const { companyId } = userInfo
	const arr = companyId ? companyId.split(',') : []
	const [comRes, chanRes, maintainRes] = await Promise.all([
		useFilter(() => companySelect({ isPermission: true }), {
			name: 'companyName',
			code: 'companyId',
		}),
		useFilter(() => channelSelect({ isPermission: true })),
		useFilter(() => maintenanceSelect('DriverService', arr, { isAll: true }), {
			name: 'realName',
			code: 'id',
		}),
	])
	if (!comRes.err) {
		const target = findItem('companyId')
		target.options = comRes.data
	}
	if (!chanRes.err) {
		const target = findItem('platformChannelId')
		target.options = chanRes.data
	}
	if (!maintainRes.err) {
		const target = findItem('driverSupportId')
		target.options = maintainRes.data
	}
}

function findItem(key) {
	return settingRef.value.filters.find(item => item.attr === key)
}

function onSizeChange(size) {
	setPage({ current: 1, size })
	fetchList()
}
function onPageChange(current) {
	const { size } = page.value
	setPage({ current, size })
	fetchList()
}

function onChangeFilter(event) {
	console.log('statusList', event)
	// const isReset = Object.keys(event).every(key => [null, undefined, ''].includes(event[key]))
	// if (isReset && selectedIndex.value) {
	// 	setParams({ page: { current: 1, size: 20 } })
	// 	fetchStatistics()
	// 	setParams({
	// 		page: { current: 1, size: 20 },
	// 		status: [statusList.value[selectedIndex.value].status],
	// 	})

	// 	fetchList(false)
	// 	return
	// }
	setParams(event)
	fetchList()
}
function onCreate() {
	formRef.value.open()
}
async function onTerminate(data) {
	if (data.status === 2) return $message.error('合同已结束')
	terminateRef.value.open(data)
}
async function onDelay(data) {
	if (data.status === 3) return $message.error('合同已作废')
	if ([5].includes(data.carStatus)) {
		return $message.error(`车辆目前状态为${mapStatus(data.carStatus)}，不允许操作延期`)
	}
	delayRef.value.open(data)
}
function onChangeCar(data) {
	changeRef.value.open(data)
}
async function onRenewal(data) {
	if (![1, 4].includes(data.status))
		return $message.error(`合同目前状态为${mapStatus(data.status)}，不允许操作续签`)
	if (![6, 7].includes(data.carStatus))
		return $message.error(`车辆目前状态为${mapCarStatus(data.carStatus)}，不允许操作续签`)
	renewalRef.value.open(data)
}
function mapStatus(code) {
	const target = mapDict('order_status', code)
	return target || '--'
}
function mapCarStatus(code) {
	const target = mapDict('carInfoStatus', code)
	return target || '--'
}
function onImport() {
	importRef.value.open()
}
function onModify() {
	if (!selection.value.length) return $message.error('请选择合同')
	const invalid = selection.value.filter(item => item.status == 2)
	if (invalid.length)
		return $message.error(`司机：${invalid.map(item => item.driverName).join('、')}的合同已结束`)
	console.log('selection.value', selection.value)
	const isSame = selection.value.every(item => item.companyId === selection.value[0].companyId)
	console.log('isSame', isSame)
	if (!isSame) return $message.error('所属公司不一致，请重新选择！')
	ModifyRef.value.open(selection.value)
}
async function fetchDict() {
	const dict = await useDict([
		'contract_period',
		'order_status',
		'order_customer_type',
		'carInfoStatus',
		'contract_type',
		'order_source',
	])
	if (dict) setDict(dict)
	// const status = findItem('status')
	const map = {
		'-2': 'waitSignNum',
		'-1': 'notActivatedNum',
		1: 'inPerformanceNum',
		2: 'endedNum',
		3: 'invalidNum',
		4: 'waitSettlementNum',
		5: 'settlementNum',
		6: 'archivedNum',
	}
	statusList.value = dict.order_status.map(val => ({
		title: val.name,
		key: map[val.code],
		status: val.code,
	}))
	if (statusList.value[0].status) {
		statusList.value.unshift({ title: '全部', key: 'allNum', status: null })
	}
	// status.options = dict.order_status.map(val => ({ name: val.name, code: val.code }))
	const contractPeriod = findItem('contractPeriod')
	contractPeriod.options = dict.contract_period.map(val => ({ name: val.name, code: val.code }))
	const customerType = findItem('orderCustomerType')
	customerType.options = dict.order_customer_type.map(val => ({
		name: val.name,
		code: val.code,
	}))
	const contractType = findItem('contractType')
	contractType.options = dict.contract_type.map(val => ({
		name: val.name,
		code: val.code,
	}))
	const target = findItem('orderSource')
	target.options = dict.order_source.map(val => ({
		name: val.name,
		code: val.code,
	}))
}
function init() {
	fetchDict()
	fetchFilters()
	const route = useRoute()
	const query = route.query
	if (Object.keys(query).length > 0) {
		params.value.keyword = query.keyword || ''
		params.value.companyId = query?.companyIds?.split(',') || []
		params.value.isValidateCar = query.isValidateCar === 'true' ? true : false
		status.value.status = params.value.status = query?.status?.split(',') || []
		const [start, end] = query?.contractEndDate?.split(',') || []
		params.value.contractEndDate =
			(query?.contractEndDate && [`${start} 00:00:00`, `${end ? end : start} 23:59:59`]) || null
		params.value.contractPeriod = query.period ? [String(query.period)] : null
		const ins = getCurrentInstance()
		ins.refs.filterRef && ins.refs.filterRef.manualSetParams(params.value)
		if (query?.status) {
			setTimeout(() => {
				let index = statusList.value.findIndex(item => item.status == query.status)
				setIndex(index)
			}, 300)
		}
	}
	fetchList()
	fetchStatistics()
}
function onSigning(row) {
	confirmRef.value.open(row)
}
onActivated(() => {
	init()
})
onMounted(() => {
	init()
})
</script>

<style lang="less" scoped>
.container {
	width: 100%;
	height: 100%;
	overflow: hidden;
	.header-title {
		height: 54px;
		line-height: 54px;
		overflow: hidden;
		float: left;
	}
	.button-list {
		margin-top: 10px;
	}
	.selected-button {
		border: 1px solid #409eff;
		color: #409eff;
	}
	.header-button {
		margin: 11px 0;
		float: right;
	}
	.cursor {
		cursor: pointer;
		color: #409eff;
	}
}
</style>
