<template>
	<el-dialog
		v-model="visible"
		width="700px"
		class="container"
		title="操作合同延期"
		:before-close="onClose"
		:close-on-click-modal="false"
		append-to-body
		destroy-on-close
	>
		<div class="delay-form-body" v-loading="isLoading">
			<div class="contract-info">
				<p>
					<span>合同ID：</span>
					<span>{{ model.orderNo }}</span>
				</p>
				<p>
					<span>合同状态：</span>
					<span>{{ mapDict('order_status', model.status) }}</span>
				</p>
				<p>
					<span>合同时间：</span>
					<span>
						{{ dayjs(model.contractStartDate).format('YYYY-MM-DD') }} ~
						{{ dayjs(model.contractEndDate).format('YYYY-MM-DD') }}
					</span>
				</p>
				<p>
					<span>合同金额：</span>
					<span>{{ model.monthlyRent }}元/月</span>
				</p>
				<p>
					<span>车牌号：</span>
					<span>{{ model.carNumber }}</span>
				</p>
				<p>
					<span>司机名称：</span>
					<span>
						{{ model.driverName }}
						<span v-if="model.driverMobile">（{{ model.driverMobile }}）</span>
					</span>
				</p>
			</div>
			<el-form
				:model="formData"
				:rules="rules"
				ref="formRef"
				label-width="120"
				style="width: 550px"
			>
				<el-form-item label="延期时长" prop="delayDays">
					<el-input
						v-model="formData.delayDays"
						@change="onDayChange"
						placeholder="输入延期天数"
						style="width: 400px"
					/>
				</el-form-item>
				<el-form-item label="合同结束时间" prop="contractEndDate">
					<el-date-picker
						disabled
						style="width: 400px"
						v-model="formData.contractEndDate"
						:disabled-date="disabledDate"
						placeholder="请选择合同结束时间"
					/>
				</el-form-item>

				<el-form-item label="延期账单" prop="isPaid">
					<el-radio-group v-model="formData.isPaid" class="ml-4">
						<el-radio :label="true" size="large">有偿账单</el-radio>
						<el-radio :label="false" size="large">无偿账单</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="账单金额" prop="amount" v-if="!!formData.isPaid">
					<el-input
						disabled
						v-model="formData.amount"
						placeholder="请输入账单金额"
						style="width: 400px"
					/>
				</el-form-item>
				<el-form-item label="延期原因" prop="delayReason">
					<el-select
						style="width: 400px"
						v-model="formData.delayReason"
						placeholder="请选择延期原因"
					>
						<el-option
							v-for="option in reasonOptions"
							:key="option.name"
							:label="option.name"
							:value="option.name"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="备注" prop="delayRemark">
					<el-input
						v-model="formData.delayRemark"
						type="textarea"
						placeholder="请输入备注"
						style="width: 400px"
						:autosize="{ minRows: 4 }"
						:maxlength="100"
						show-word-limit
					/>
				</el-form-item>
				<el-form-item label="凭证附件" prop="delayAnnexesArr">
					<span>({{ model.delayAnnexesArr ? model.delayAnnexesArr.length : 0 }}/9)</span>
					<yi-file-uploader
						v-model="formData.delayAnnexesArr"
						type="file"
						source-type="image"
						product-type="renrenjia"
						accept=".jpg,.jpeg,.png,.xlsx,.xls,.pdf,.zip,.rar,.pptx,.docx"
						:max="9"
						:uploader-size="20"
					/>
				</el-form-item>
			</el-form>
		</div>

		<template #footer>
			<div class="dialog-footer">
				<el-button type="primary" @click="onSubmit">确定</el-button>
				<el-button @click="onClose">取消</el-button>
			</div>
		</template>
	</el-dialog>
</template>

<script setup>
import dayjs from 'dayjs'
import { defineExpose, inject, defineEmits, ref, watch } from 'vue'
import { useState, useModal, useFetch, useDict } from '@/utils/hooks.js'
import { orderExtension } from '@/api/driver.js'

const $message = inject('$message')
const emit = defineEmits(['on-refresh'])
const { visible, setVisible } = useModal()
const [model, setModel] = useState({})
const [formData, setForm] = useState({})
const [dict, setDict] = useState({})
const [reasonOptions, setOptions] = useState([])
const { isLoading, fetchData } = useFetch()

const formRef = ref(null)

watch(
	() => formData.value.isPaid,
	val => {
		if (val) {
			const rent = model.value.monthlyRent || 0
			const day = formData.value.delayDays || 0
			formData.value.amount = ((rent / 30) * day).toFixed(2)
		} else {
			formData.value.amount = 0
		}
	},
)
watch(
	() => formData.value.delayDays,
	val => {
		if (val) {
			formData.value.amount = ((model.value.monthlyRent / 30) * val).toFixed(2)
		} else {
			formData.value.amount = 0
		}
	},
)

function validator(_, value, callback) {
	if (value > 30) {
		callback(new Error('超过1个月请重新签约或多次延期'))
	} else {
		callback()
	}
}
const rules = {
	contractEndDate: [{ required: true, message: '请选择合同结束时间', trigger: 'blur' }],
	delayDays: [
		{ required: true, message: '请选择延期时间', trigger: 'blur' },
		{ pattern: /^[0-9]*$/, message: '延期时间必须是整数', trigger: 'blur' },
		{ validator, trigger: 'blur' },
	],
	isPaid: [{ required: true, message: '请选择延期账单', trigger: 'blur' }],
	delayReason: [{ required: true, message: '请选择延期原因', trigger: 'blur' }],
	delayRemark: [{ required: true, message: '请输入备注', trigger: 'blur' }],
	amount: [{ required: true, message: '请输入账单金额', trigger: 'blur' }],
}

function open(data) {
	setVisible(true)
	setModel(data)
	formData.value.contractEndDate = data.contractEndDate
}

function onClose() {
	setVisible(false)
	setForm({})
}
function mapDict(key, value) {
	const e = dict.value[key]
	if (!e) return '--'
	const target = e.find(item => item.code == value)

	return target ? target.name : '--'
}
async function fetchDict() {
	const dict = await useDict(['order_delay_reason', 'order_status'])
	setOptions(dict.order_delay_reason)
	if (dict) setDict(dict)
}
function disabledDate(time) {
	return (
		time.getTime() <
		new Date(dayjs(model.value.contractEndDate).format('YYYY-MM-DD') + ' 00:00:00').getTime()
	)
}
function onDayChange(day) {
	const cur = model.value.contractEndDate
	formData.value.contractEndDate = dayjs(cur).add(day, 'day')
}
async function onSubmit() {
	await formRef.value.validate()
	const { orderId } = model.value
	const { contractEndDate } = formData.value
	const { err } = await fetchData(orderExtension, {
		orderId,
		...formData.value,
		contractEndDate: dayjs(contractEndDate).format('YYYY-MM-DD') + ' 23:59:59',
	})
	if (err) return $message.error(err.message)
	$message.success('合同延期提交成功')
	emit('on-refresh')
	onClose()
}
fetchDict()
defineExpose({ open })
</script>

<style lang="less" scoped>
.title {
	border-left: 3px solid #2e3443;
	color: #2e3443;
	padding-left: 4px;
	font-size: 16px;
	font-weight: 500;
}
.contract-info {
	padding: 0 50px 20px 50px;
}
</style>
