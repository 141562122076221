export const columns = [
	{
		prop: 'orderNo',
		label: '合同ID',
		width: '200px',
	},
	{
		prop: 'carModel',
		label: '车型名称',
		width: '280px',
	},
	{
		prop: 'carNumber',
		label: '车牌号',
		width: '160px',
	},

	{
		prop: 'driverName',
		label: '司机姓名',
		width: '140px',
	},
	{
		prop: 'driverSupportName',
		label: '司服伙伴',
		width: '140px',
	},
	{
		prop: 'orderSource',
		label: '订单来源',
		width: '140px',
	},
	{
		prop: 'contractType',
		label: '合同类型',
		width: '140px',
	},
	{
		prop: 'status',
		label: '合同状态',
		width: '140px',
	},
	{
		prop: 'platformChannel',
		label: '平台渠道',
		width: '120px',
	},
	{
		prop: 'contractStartDate',
		label: '合同开始日期',
		width: '180px',
	},
	{
		prop: 'contractPeriod',
		label: '合同期数(月)',
		width: '140px',
	},
	// {
	// 	prop: 'monthlyRent',
	// 	label: '月租（元）',
	// 	width: '140px',
	// },
	{
		prop: 'contractEndDate',
		label: '合同到期日期',
		width: '220px',
	},
	{
		prop: 'dueDate',
		label: '每月还款日期',
		width: '140px',
	},
	{
		prop: 'companyName',
		label: '所属公司',
		width: '300px',
	},
	{
		prop: 'createTime',
		label: '创建时间',
		width: '250px',
	},
	{
		prop: 'createUser',
		label: '创建人',
		width: '140px',
	},
	// {
	// 	prop: 'contractAnnexesArr',
	// 	label: '合同文件',
	// 	width: '100px',
	// },
	{
		prop: 'operation',
		label: '操作',
		fixed: 'right',
		width: '200px',
	},
]
export const setting = {
	expand: true,
	filters: [
		{
			placeholder: '输入车型名称、车牌、自编号、司机姓名、手机号搜索',
			label: '车辆信息',
			attr: 'keyword',
			type: 'search',
			width: 358,
		},
		{
			placeholder: '请选择车牌号',
			label: '车牌号',
			attr: 'licensePlateNums',
			isCustom: true,
			options: [],
		},
		{
			label: '合同开始时间',
			attr: 'contractStartDate',
			type: 'date',
		},
		// {
		// 	placeholder: '请选择合同状态',
		// 	label: '合同状态',
		// 	attr: 'status',
		// 	type: 'multi-select',
		// 	options: [],
		// },
		{
			placeholder: '请选择所属公司',
			label: '所属公司',
			attr: 'companyId',
			type: 'multi-select',
			width: 320,
			options: [],
		},
		{
			placeholder: '请选择司服伙伴',
			label: '司服伙伴',
			attr: 'driverSupportId',
			type: 'select',
			options: [],
		},
		{
			placeholder: '请选择平台渠道',
			label: '平台渠道',
			attr: 'platformChannelId',
			type: 'multi-select',
			options: [],
		},
		{
			placeholder: '请选择订单来源',
			label: '订单来源',
			attr: 'orderSource',
			type: 'multi-select',
			options: [],
		},
		{
			placeholder: '请选择合同类型',
			label: '合同类型',
			attr: 'contractType',
			type: 'select',
			options: [],
		},
		{
			placeholder: '请选择合同期数',
			label: '合同期数',
			attr: 'contractPeriod',
			type: 'multi-select',
			options: [],
		},
		{
			label: '合同到期时间',
			attr: 'contractEndDate',
			type: 'date',
		},
		{
			label: '客户类型',
			attr: 'orderCustomerType',
			type: 'select',
			options: [],
		},
		{
			label: '签署方式',
			attr: 'isESign',
			type: 'select',
			options: [
				{
					name: '电子合同',
					code: true,
				},
				{
					name: '纸质合同',
					code: false,
				},
			],
		},
		{
			label: '是否验车',
			attr: 'isValidateCar',
			type: 'select',
			options: [
				{
					name: '车辆有验车单',
					code: true,
				},
				{
					name: '车辆无验车单',
					code: false,
				},
			],
		},
	],
}

export const form = [
	{
		prop: 'companyId',
		label: '所属公司',
		required: true,
		component: 'el-select',
		custom: true,
		event: 'onCompanyChange',
		attrs: { placeholder: '请选择所属公司' },
	},
	{
		prop: 'orderCustomerType',
		label: '客户类型',
		required: true,
		custom: true,
	},
	{
		prop: 'driverId',
		label: '司机姓名',
		required: true,
		custom: true,
		attrs: { placeholder: '请选择司机', filterable: true },
	},
	{
		prop: 'driverId',
		label: '公司名称',
		hide: true,
		required: true,
		component: 'el-select',
		attrs: { placeholder: '请选择公司' },
	},
	{
		prop: 'carId',
		label: '车牌号',
		required: true,
		component: 'el-select',
		custom: true,
		attrs: { placeholder: '请选择车牌号', filterable: true },
	},
	{
		prop: 'orderSource',
		label: '订单来源',
		required: true,
		component: 'el-select',
		attrs: { placeholder: '请选择订单来源' },
	},
	{
		prop: 'recommendMobile',
		label: '推荐人',
		hide: true,
		required: true,
		component: 'el-input',
		attrs: { placeholder: '请输入推荐人手机号', maxLength: 11 },
	},
	{
		prop: 'orderSourceChannel',
		label: '渠道收口',
		hide: true,
		required: true,
		component: 'el-select',
		attrs: { placeholder: '请选择渠道收口' },
	},
	{
		prop: 'internetPlatform',
		label: '网络平台',
		hide: true,
		required: true,
		component: 'el-select',
		attrs: { placeholder: '请选择网络平台' },
	},
	{
		prop: 'salesmanId',
		label: '招募人员',
		hide: true,
		required: true,
		component: 'el-select',
		attrs: { placeholder: '请选择招募人员' },
	},
	{
		prop: 'platformChannelId',
		label: '平台渠道',
		required: true,
		component: 'el-select',
		attrs: { placeholder: '请选择平台渠道' },
	},
	{
		prop: 'goodsLeaseId',
		label: '商品方案',
		required: true,
		component: 'el-select',
		attrs: { placeholder: '请选择商品方案' },
	},
	{
		prop: 'leasingSchemeName',
		label: '商品方案',
		hide: true,
		required: true,
		component: 'el-input',
		attrs: { placeholder: '请输入商品方案' },
	},
	{
		prop: 'giftDays',
		label: '期满赠送',
		hide: true,
		component: 'el-input',
		attrs: { placeholder: '' },
	},
	{
		prop: 'penaltyProportion',
		label: '提前退车违约金',
		hide: true,
		component: 'el-input',
		attrs: { placeholder: '' },
	},
	// {
	// 	prop: 'marketingPlan',
	// 	label: '营销方案',
	// 	component: 'el-input',
	// 	custom: true,
	// 	attrs: { placeholder: '请输入营销方案', maxLength: 20 },
	// },
	{
		prop: 'driverSupportId',
		label: '司服伙伴',
		required: true,
		component: 'el-select',
		attrs: { placeholder: '请选择司服伙伴' },
	},
]
export const contractForm = [
	{
		prop: 'contractStartDate',
		label: '合同开始',
		required: true,
		custom: true,
		attrs: { placeholder: '请选择合同开始日期' },
	},
	{
		prop: 'contractPeriod',
		label: '合同期数',
		required: true,
		custom: true,
		attrs: { placeholder: '请选择合同期数' },
	},
	{
		prop: 'contractEndDate',
		label: '合同结束',
		required: true,
		custom: true,
		attrs: { placeholder: '' },
	},
	// {
	// 	prop: 'monthlyRent',
	// 	label: '合同月租',
	// 	required: true,
	// 	component: 'el-input',
	// 	attrs: { placeholder: '请输入合同月租', maxLength: 5 },
	// },
	{
		prop: 'monthlyRent',
		label: '合同月租',
		required: true,
		custom: true,
	},
	{
		prop: 'monthlyRentGenerateType',
		label: '月租生成',
		required: true,
		component: 'el-select',
		attrs: { placeholder: '请选择生成方式' },
	},
	{
		prop: 'dueDate',
		label: '还款日',
		required: true,
		component: 'el-select',
		attrs: { placeholder: '请选择还款日' },
		options: Array.from({ length: 31 }, (_, i) => ({ label: i + 1, value: i + 1 })),
	},

	{
		prop: 'securityDeposit',
		label: '合同押金',
		required: true,
		component: 'el-input',
		attrs: { placeholder: '请输入合同押金' },
	},
	{
		prop: 'depositPeriod',
		label: '押金期数',
		required: true,
		component: 'el-select',
		attrs: { placeholder: '请选择押金期数' },
		options: [],
	},
]

export const renewalForm = [
	{
		prop: 'companyName',
		label: '所属公司',
		required: true,
		component: 'el-select',
		attrs: { disabled: true },
	},
	// {
	// 	prop: 'orderNo',
	// 	label: '合同ID',
	// 	required: true,
	// 	component: 'el-input',
	// 	attrs: { disabled: true },
	// },
	{
		prop: 'driverName',
		label: '司机姓名',
		required: true,
		component: 'el-input',
		attrs: { disabled: true },
	},
	{
		prop: 'carNumber',
		label: '车牌号',
		required: true,
		component: 'el-input',
		cWidth: '500',
		attrs: { disabled: true },
	},
	{
		prop: 'orderSource',
		label: '订单来源',
		required: true,
		component: 'el-select',
		attrs: { placeholder: '请选择订单来源', disabled: true },
	},
	{
		prop: 'platformChannelId',
		label: '平台渠道',
		required: true,
		component: 'el-select',
		attrs: { placeholder: '请选择平台渠道' },
	},
	{
		prop: 'goodsLeaseId',
		label: '商品方案',
		required: true,
		component: 'el-select',
		attrs: { placeholder: '请选择商品方案' },
	},
	{
		prop: 'leasingSchemeName',
		label: '商品方案',
		hide: true,
		required: true,
		component: 'el-input',
		attrs: { placeholder: '请选择商品方案' },
	},
	{
		prop: 'leasingSchemeName',
		label: '商品方案',
		hide: true,
		required: true,
		component: 'el-input',
		attrs: { placeholder: '请输入商品方案' },
	},
	{
		prop: 'contractStartDate',
		label: '合同开始',
		required: true,
		component: 'el-input',
		attrs: { disabled: true },
	},
	{
		prop: 'dueDate',
		label: '还款日',
		required: true,
		component: 'el-select',
		options: Array.from({ length: 31 }, (_, i) => ({ label: i + 1, value: i + 1 })),
		attrs: { placeholder: '请选择还款日' },
	},
	{
		prop: 'contractPeriod',
		label: '合同期数',
		required: true,
		component: 'el-select',
		attrs: { placeholder: '请选择合同期数', 'value-key': 'id' },
	},
	{
		prop: 'contractPeriod',
		label: '合同期数',
		hide: true,
		required: true,
		component: 'el-input',
		attrs: { placeholder: '请输入合同期数' },
	},
	{
		prop: 'contractEndDate',
		label: '合同结束',
		required: true,
		custom: true,
	},
	{
		prop: 'monthlyRent',
		label: '合同月租',
		required: true,
		component: 'el-input',
		attrs: { placeholder: '请输入合同月租', disabled: true },
	},
	// {
	// 	prop: 'securityDeposit',
	// 	label: '合同押金',
	// 	required: true,
	// 	component: 'el-input',
	// 	attrs: { placeholder: '请输入合同押金' },
	// },
	{
		prop: 'monthlyRentGenerateType',
		label: '月租生成',
		required: true,
		component: 'el-select',
		attrs: { placeholder: '请选择生成方式' },
	},
	{
		prop: 'renewalRemark',
		label: '续签备注',
		required: true,
		component: 'el-input',
		cWidth: '500',
		attrs: {
			type: 'textarea',
			maxLength: 200,
			autosize: { minRows: 4, maxRows: 8 },
		},
	},
]
export const logColumns = [
	{
		prop: 'createTime',
		label: '时间',
		width: '200px',
	},
	{
		prop: 'createByName',
		label: '操作人',
		width: '120px',
	},
	{
		prop: 'content',
		label: '操作内容',
	},
]

export const delayColumns = [
	{
		prop: 'createTime',
		label: '延期操作时间',
		width: '200px',
	},
	{
		prop: 'createUser',
		label: '延期操作人',
		width: '120px',
	},
	{
		prop: 'delayTradeBillName',
		label: '延期账单',
		width: '120px',
	},
	{
		prop: 'delayDays',
		label: '延期天数',
		width: '100px',
	},
	{
		prop: 'delayEndDate',
		label: '延期结束时间',
		width: '160px',
	},
	{
		prop: 'delayReason',
		label: '延期原因',
		width: '180px',
	},
	{
		prop: 'delayRemark',
		label: '备注',
		width: '260px',
	},
	{
		prop: 'delayAnnexesArr',
		label: '凭证附件',
		width: '100px',
	},
]
