<template>
	<el-drawer
		class="container"
		v-model="visible"
		:append-to-body="false"
		:show-close="false"
		size="1100"
		:with-header="false"
	>
		<div v-loading="isLoading" class="detail-container">
			<div class="header">
				<div>
					<p class="title">
						司机：{{ detail.driverName }} （{{ mapStatus(detail.status, 'order_status') }}）
					</p>
					<div class="top-container-main">
						<span class="label">
							所属公司：
							<span class="value">{{ detail.companyName }}</span>
						</span>
						<span class="label">
							创建时间：
							<span class="value">{{ detail.createTime }}</span>
						</span>
						<span class="label">
							最后更新时间：
							<span class="value">{{ detail.updateTime }}</span>
						</span>
					</div>
				</div>

				<el-icon size="28" color="#707070" style="cursor: pointer" @click="onClose">
					<CircleClose />
				</el-icon>
			</div>

			<div class="main-container">
				<div class="left-container">
					<div class="button-list">
						<el-button class="button" @click="onEdit">编辑</el-button>
						<el-button
							class="button"
							@click="onInvalid"
							v-if="detail.status !== 3"
							v-permission="'pc/driver-center/contract-manage/invalid'"
						>
							作废
						</el-button>
					</div>
					<div class="left-label">司机姓名</div>
					<div class="staff">
						<div class="avatar" v-if="detail.driverName">
							<p>
								{{ formatName(detail.driverName) }}
							</p>
						</div>
						<p>{{ detail.driverName }}</p>
						<p class="staff-phone">{{ detail.mobile }}</p>
					</div>
					<div class="left-label">司服伙伴</div>
					<div class="staff">
						<div class="avatar" v-if="detail.driverSupportName">
							<p>
								{{ formatName(detail.driverSupportName) }}
							</p>
						</div>
						<p>{{ detail.driverSupportName }}</p>
						<p class="staff-phone">{{ detail.driverSupportMobile }}</p>
					</div>
				</div>
				<div class="tab-container">
					<StateFlow :stateList="stateList" />
					<el-tabs v-model="activeTab" class="tab-container-self" @tab-change="handleTabClick">
						<el-tab-pane name="driverTab" label="合同信息">
							<contractTab :data="detail" v-if="activeTab === 'driverTab'" />
						</el-tab-pane>

						<el-tab-pane name="billTab" label="账单信息">
							<BillTab :data="detail" v-if="activeTab === 'billTab'" />
						</el-tab-pane>

						<el-tab-pane name="operationTab" label="操作记录">
							<OperationTab :data="detail" v-if="activeTab === 'operationTab'" />
						</el-tab-pane>
					</el-tabs>
				</div>
			</div>
			<edit-form ref="formRef" @on-refresh="refreshDetail" />
		</div>
	</el-drawer>
</template>

<script setup>
import { defineExpose, inject, ref, defineEmits } from 'vue'
import { useState, useFetch, useDict } from '@/utils/hooks.js'
import { contractDetail, contractInvalid } from '@/api/driver'
import Request from '@/http'
import contractTab from './contractTab'
import { ElMessageBox } from 'element-plus'
import BillTab from './billTab'
import OperationTab from './operationTab'
import EditForm from '../form'
import StateFlow from '@/components/StateFlow'

const $message = inject('$message')
const emit = defineEmits(['on-refresh'])
const [visible, setVisible] = useState(false)
const [activeTab, setTab] = useState('vehicle')
const [dict, setDict] = useState({})
const [detail, setDetailData] = useState({})
const [stateList, setStateList] = useState([])
const { isLoading, fetchData } = useFetch()

const formRef = ref(null)

function onEdit() {
	formRef.value.open(detail.value)
}
async function onInvalid() {
	ElMessageBox.confirm('确认作废该条合同?', '提示', {
		confirmButtonText: '确认',
		cancelButtonText: '取消',
		type: 'warning',
	})
		.then(async () => {
			const { err } = await fetchData(contractInvalid, { orderId: detail.value.orderId })
			if (err) return $message.error(err.message)
			$message.success('作废成功')
			emit('on-refresh')
			refreshDetail()
		})
		.catch(() => {})
}
function onClose() {
	setVisible(false)
	setDetailData({})
}
function formatName(name) {
	return name.substring(name.length - 2, name.length)
}

async function fetchDetail(id) {
	const { data, err } = await fetchData(contractDetail, id)
	if (err) return $message.error(err.message)
	setDetailData(data)
	const statusName = mapStatus(data.status, 'order_status')
	const orderSourceName = mapStatus(data.orderSource, 'order_source')
	const orderSourceChannelName = data.orderSourceChannel
		? mapStatus(data.orderSourceChannel, 'order_source_channel')
		: ''
	const internetPlatformName = data.internetPlatform
		? mapStatus(data.internetPlatform, 'internet_platform')
		: ''
	detail.value.orderSourceName = orderSourceName
	detail.value.statusName = statusName
	const sexName = mapStatus(data.sex, 'sex')
	detail.value.sexName = sexName
	detail.value.orderSourceChannelName = orderSourceChannelName
	detail.value.internetPlatformName = internetPlatformName
}
function refreshDetail() {
	fetchDetail(detail.value.orderId)
}
function mapStatus(value, type) {
	const target = dict.value[type].find(item => item.code == value)
	if (!target) return '--'
	return target.name
}
function handleTabClick(val) {
	setTab(val)
	// switch (val) {
	// 	case 'driverTab':
	// 		driverTab.value && driverTab.value.init()
	// 		break
	// 	case 'contractTab':
	// 		contractTab.value && contractTab.value.init()
	// 		break
	// 	case 'billTab':
	// 		billTab.value && billTab.value.init()
	// 		break
	// 	case 'violationTab':
	// 		violationTab.value && violationTab.value.init()
	// 		break
	// 	case 'operationTab':
	// 		operationTab.value && operationTab.value.init()
	// 		break
	// 	default:
	// 		break
	// }
}
async function fetchDict() {
	const dict = await useDict([
		'order_status',
		'sex',
		'contact_person_type',
		'order_source',
		'order_source_channel',
		'internet_platform',
	])
	if (dict) setDict(dict)
}
async function fetchStateFlow(id) {
	Request({
		url: `/suf4-asset-service/order/info/listOrderInfoStatusFlow?orderId=${id}`,
		method: 'GET',
	})
		.then(res => {
			if (res.code === 200) {
				setStateList(
					(res.data || []).map(item => {
						return {
							...item,
							descList: [`状态名称：${item.statusName}`, `状态说明：${item.desc || '无'}`],
						}
					}),
				)
			} else {
				throw new Error(res.msg)
			}
		})
		.catch(error => {
			console.log('getListOrderInfoStatusFlow失败 ==>', error)
			return false
		})
}

async function open(id) {
	await fetchDict()
	fetchDetail(id)
	fetchStateFlow(id)
	setTab('driverTab')
	setVisible(true)
}
defineExpose({ open })
</script>
<style lang="less">
.container > .el-drawer__body {
	padding: 0 !important;
}
</style>
<style lang="less" scoped>
.detail-container {
	width: 100%;
	height: 100%;
	.flexible(column, flex-start, flex-start);
	overflow: hidden;
}
.header {
	padding: 5px 0 10px 24px;
	background-color: #fff;
	.flexible(row,center, space-between);
	width: 1050px;
	.title {
		font-size: 18px;
		margin-bottom: 8px;
	}
	.extraInfo {
		color: #cfcfcf;
	}
	.top-container-main {
		display: flex;
		align-items: center;
		padding-right: 24px;
		.top-container-main-right {
			.button {
				margin-right: 10px;
			}
		}
		.label {
			font-size: 12px;
			color: #707070;
			margin-right: 10px;
		}
		.value {
			font-size: 12px;
			color: rgba(0, 0, 0.84);
			&.click-tip {
				color: #409eff;
			}
		}
	}
}
.main-container {
	flex: 1;
	.flexible(row, flex-start, flex-start);
	border-top: 7px solid #f0f2f5;
	width: 100%;
	height: 0;
	.left-container {
		width: 338px;
		height: 100%;
		padding: 16px;
		overflow-y: auto;
		.button-list {
			.flexible(row, center, flex-start);
			flex-wrap: wrap;
			.button {
				width: 120px;
				height: 36px;
				border: 1px solid #dcdfe6;
				border-radius: 4px;
				.flexible(row, center, center);
				color: #606266;
				margin: 0 20px 20px 20px;
			}
		}
		.left-label {
			border-left: 3px solid #2e3443;
			padding-left: 4px;
			line-height: 15px;
			font-size: 16px;
			margin: 10px 0;
		}
		.staff {
			.flexible(row, center, flex-start);
			margin-right: 20px;
			.staff-phone {
				margin-left: 20px;
				font-size: 14px;
			}
		}
		.avatar {
			height: 30px;
			width: 30px;
			border-radius: 15px;
			margin-right: 20px;
			.flexible(row, center, center);
			background-color: #409eff;
			color: white;
			text-align: center;
			font-size: 14px;
		}
	}
	.tab-container {
		border-left: 7px solid #f0f2f5;
		padding: 8px 20px;
		flex: 1;
		height: 100%;
		width: 0;
		overflow: auto;
	}
	.tab-container::-webkit-scrollbar {
		width: 0 !important;
	}
}
</style>
