import request from '@/http'
import { useAppStore } from '@/store/modules/app'

// 获取天眼查企业列表
export function getEyeApiData(data) {
	return request({
		url: '/api/yiautos-psf-shop/eye/getEyeApiData',
		method: 'POST',
		params: data,
	})
}

// 获取天眼查企业详情
export function getEyeApiDetail(data) {
	return request({
		url: '/api/yiautos-psf-shop/eye/getEyeApiDetailsData',
		method: 'POST',
		data,
	})
}

// 获取树形区域列表
export function getAreaList() {
	return request({
		url: '/api/yiautos-basic/region/list-region-tree',
		method: 'GET',
	})
}

// 获取小程序码
export function getQrcode(data) {
	const appStore = useAppStore()
	return request({
		url: '/suf4-system-service/wechat/generator/wxaCode',
		method: 'POST',
		data,
		headers: {
			'Tenant-Id': appStore.tenantId,
			'App-Type': 'driverMiniapp',
		},
	})
}
