<template>
	<el-dialog
		v-model="visible"
		width="390px"
		class="container"
		:before-close="onClose"
		:show-close="false"
		append-to-body
		destroy-on-close
		v-loading="isLoading"
	>
		<div id="printId">
			<p class="title">汽车租赁合同信息确认</p>
			<div class="body-block">
				<div v-for="(info, index) in infoListCom" :key="index" class="info-row">
					<div class="label">{{ info.label }}</div>
					<div class="value">{{ info.value }}</div>
				</div>
				<img :src="qrImg" class="qr-img" />
				<div class="tip">请使用微信扫一扫，登录乙方小程序签署合同</div>
			</div>
		</div>
	</el-dialog>
</template>
<script setup>
import dayjs from 'dayjs'
import convert from 'dom-to-image'
import { ref, defineExpose, computed, inject, defineEmits } from 'vue'
import { useState, useModal, useFetch, useDict } from '@/utils/hooks.js'
import { contractDetail } from '@/api/driver'
import { getQrcode } from '@/api/common'

const { visible, setVisible } = useModal()
const [contractInfo, setContractDetail] = useState([])
const { isLoading, fetchData } = useFetch()
const qrImg = ref('')

const infoListCom = computed(() => {
	const {
		orderNo,
		carModel,
		carColor,
		carNumber,
		securityDeposit,
		monthlyRent,
		contractPeriod,
		contractStartDate,
		contractEndDate,
	} = contractInfo.value
	return [
		{
			label: '合同ID',
			value: orderNo,
		},
		{
			label: '车型',
			value: carModel,
		},
		{
			label: '车身颜色',
			value: carColor,
		},
		{
			label: '车牌号',
			value: carNumber,
		},
		{
			label: '押金（元）',
			value: securityDeposit,
		},
		{
			label: '固定租期月租（元）',
			value: monthlyRent,
		},
		{
			label: '固定租期（月）',
			value: contractPeriod,
		},
		{
			label: '起租日',
			value: contractStartDate,
		},
		{
			label: '到期日',
			value: contractEndDate,
		},
	]
})

const open = async row => {
	const [detailRes, qrRes] = await Promise.all([
		fetchData(contractDetail, row.orderId),
		fetchData(getQrcode, {
			path: `/pages/contract/detail?id=${row.orderId}`,
			width: 280,
		}),
	])
	if (!detailRes.err) {
		setContractDetail(detailRes.data || [])
	}
	if (!qrRes.err) {
		qrImg.value = qrRes.data
	}
	setVisible(true)
}

const onClose = () => {
	setVisible(false)
}

defineExpose({ open, onClose })
</script>
<style lang="less" scoped>
#printId {
	margin-top: -60px;
	.title {
		text-align: center;
		font-size: 24px;
		color: rgba(0, 0, 0, 0.84);
		font-weight: 600;
	}
	.body-block {
		.info-row {
			display: flex;
			line-height: 28px;
			font-size: 14px;
			color: #333;
			.label {
				width: 150px;
				flex: 0 0 150px;
			}
			.value {
				flex: 1;
			}
		}
		.qr-img {
			margin: 10px 105px 10px;
			width: 140px;
			// height: 100px;
		}
		.tip {
			color: #000;
			font-size: 14px;
			text-align: center;
		}
	}
}
.el-dialog__header {
	display: none !important;
}
/deep/ .el-dialog__body {
	padding: 0 !important;
}
</style>
