<template>
	<el-dialog
		v-model="visible"
		width="600px"
		class="container"
		title="操作合同换车"
		:before-close="onClose"
		:close-on-click-modal="false"
		append-to-body
		destroy-on-close
	>
		<div v-loading="isLoading">
			<p>
				<span>合同ID：</span>
				<span>{{ model.orderNo }}</span>
			</p>
			<p>
				<span>合同状态：</span>
				<span>{{ mapDict('order_status', model.status) }}</span>
			</p>
			<p>
				<span>合同时间：</span>
				<span>
					{{ dayjs(model.contractStartDate).format('YYYY-MM-DD') }} ~
					{{ dayjs(model.contractEndDate).format('YYYY-MM-DD') }}
				</span>
			</p>
			<p>
				<span>合同金额：</span>
				<span>{{ model.monthlyRent }}元/月</span>
			</p>
			<p>
				<span>司机名称：</span>
				<span>{{ model.driverName }}</span>
			</p>
			<p style="font-weight: 600">确定将合同当前车辆 {{ model.carNumber }} 进行更换</p>
		</div>
		<el-form :model="model" :rules="rules" ref="formRef" label-width="120" style="width: 400px">
			<el-form-item label="合同新车辆" prop="carId">
				<el-select-v2
					placeholder="请选择车牌号"
					:options="licensePlateOptions"
					filterable
					size="default"
					v-model="model.carId"
					style="width: 190px"
				>
					<template #default="{ item }">
						<div class="custom-option">
							<span>{{ item.label }}</span>
							<span>{{ item.statusName }}</span>
						</div>
					</template>
				</el-select-v2>
			</el-form-item>
		</el-form>
		<template #footer>
			<div class="dialog-footer">
				<el-button type="primary" @click="onSubmit">确定</el-button>
				<el-button @click="onClose">取消</el-button>
			</div>
		</template>
	</el-dialog>
</template>

<script setup>
import dayjs from 'dayjs'
import { defineExpose, inject, defineEmits, ref } from 'vue'
import { useState, useModal, useFetch, useDict } from '@/utils/hooks.js'
import { changeCar } from '@/api/driver.js'
import { carSelectList } from '@/api/vehicle.js'
import { status } from '../../vehicle/enums'

const rules = {
	carId: [{ required: true, message: '请选择车辆' }],
}
const formRef = ref(null)
const $message = inject('$message')
const emit = defineEmits(['on-refresh'])
const { visible, setVisible } = useModal()
const [model, setModel] = useState({})
const [dict, setDict] = useState({})
const { isLoading, fetchData } = useFetch()
const [licensePlateOptions, setOptions] = useState([])

function open(data = []) {
	fetchList()
	setVisible(true)
	setModel({ ...data, carId: undefined })
}

function onClose() {
	setVisible(false)
	setModel({})
}
function mapDict(key, value) {
	const e = dict.value[key]
	if (!e) return '--'
	const target = e.find(item => item.code == value)

	return target ? target.name : '--'
}
async function fetchDict() {
	const dict = await useDict('order_status')
	if (dict) setDict(dict)
}
function mapStatus(value) {
	const target = status.find(item => item.code == value)
	return target ? target.name : '--'
}
async function fetchList() {
	const { err, data } = await fetchData(carSelectList, { statusList: [1, 2, 3, 4, 5, 6, 7, 8] })
	if (err) return $message.error(err.message)
	const options = data.map(item => ({
		...item,
		label: item.licensePlateNum,
		value: item.id,
		disabled: ![4].includes(item.status),
		statusName: mapStatus(item.status),
	}))
	const valid = []
	const invalid = []
	for (const option of options) {
		if ([4].includes(option.status)) valid.push(option)
		else invalid.push(option)
	}
	setOptions(valid.concat(invalid))
}
async function onSubmit() {
	await formRef.value.validate()
	const { orderId, carId } = model.value
	const { err } = await fetchData(changeCar, { orderId, carId })
	if (err) return $message.error(err.message)
	$message.success('合同换车提交成功')
	emit('on-refresh')
	onClose()
}
fetchDict()
defineExpose({ open })
</script>

<style lang="less" scoped>
.title {
	border-left: 3px solid #2e3443;
	color: #2e3443;
	padding-left: 4px;
	font-size: 16px;
	font-weight: 500;
}
.custom-option {
	.flexible(row, center, space-between);
}
</style>
