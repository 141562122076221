<template>
	<el-dialog
		v-model="visible"
		width="600px"
		class="container"
		title="修改司服伙伴"
		:before-close="onClose"
		:close-on-click-modal="false"
		append-to-body
		destroy-on-close
	>
		<div v-loading="isLoading">
			<p>
				已选中
				<span style="color: red; font-size: 14px; font-weight: 500">{{ model.length }}</span>
				名司机，司机明细如下：
			</p>
			<p>{{ drivers }}</p>
			<p>确定批量更新以上人员的司服伙伴</p>
			<el-form :model="formData" :rules="rules" ref="formRef">
				<el-form-item label="司服伙伴" prop="driverSupportId">
					<el-select v-model="formData.driverSupportId">
						<el-option
							v-for="item in maintainer"
							:key="item.value"
							:label="item.label"
							:value="item.value"
						></el-option>
					</el-select>
				</el-form-item>
			</el-form>
		</div>

		<template #footer>
			<div class="dialog-footer">
				<el-button type="primary" @click="onSubmit">确定</el-button>
				<el-button @click="onClose">取消</el-button>
			</div>
		</template>
	</el-dialog>
</template>

<script setup>
import { defineExpose, inject, defineEmits, ref } from 'vue'
import { useState, useModal, useFetch, useSelect } from '@/utils/hooks.js'
import { modifyMaintainer } from '@/api/driver.js'
import { getStore } from '@/utils/store'
import { maintenanceSelect } from '@/api/vehicle.js'

const $message = inject('$message')
const emit = defineEmits(['on-refresh'])
const { visible, setVisible } = useModal()
const [model, setModel] = useState([])
const [maintainer, setMaintainer] = useState([])
const [drivers, setDrivers] = useState('')
const [formData, setForm] = useState({})

const { isLoading, fetchData } = useFetch()

const formRef = ref(null)
const rules = {
	driverSupportId: [{ required: true, message: '请选择司服伙伴', trigger: 'blur' }],
}

async function open(data) {
	const userInfo = getStore({ name: 'userInfo' })
	setVisible(true)
	setModel(data)
	const str = data.map(item => item.driverName).join('、')
	setDrivers(str)
	const { companyId } = userInfo
	const list = companyId ? companyId.split(',') : []
	const fn = () => maintenanceSelect('DriverService', list, { isAll: true })
	const res = await useSelect(fn, { name: 'realName', code: 'id' })
	setMaintainer(res.data)
}

function onClose() {
	setVisible(false)
	setForm({})
}

async function onSubmit() {
	await formRef.value.validate()
	const { driverSupportId } = formData.value
	const orderIds = model.value.map(item => item.orderId)
	const { err } = await fetchData(modifyMaintainer, {
		orderIds,
		driverSupportId,
	})
	if (err) return $message.error(err.message)
	$message.success('修改司服务伙伴成功')
	emit('on-refresh')
	onClose()
}
defineExpose({ open })
</script>

<style lang="less" scoped>
.title {
	border-left: 3px solid #2e3443;
	color: #2e3443;
	padding-left: 4px;
	font-size: 16px;
	font-weight: 500;
}
</style>
