<template>
	<div>
		<el-dialog
			v-model="visible"
			width="850px"
			class="container"
			:title="title === '合同详情' ? '' : title"
			:before-close="onClose"
			:close-on-click-modal="false"
			append-to-body
			destroy-on-close
		>
			<el-tabs
				v-if="title === '合同详情'"
				style="margin: 0 50px 20px 50px"
				v-model="activeTab"
				type="card"
				class="demo-tabs"
				@tab-click="onTabChange"
			>
				<el-tab-pane :label="title" name="form"></el-tab-pane>
				<el-tab-pane label="操作日志" name="log">
					<yi-table
						table-height="400px"
						:loading="tableLoading"
						:columns="logColumns"
						:data="logData"
					/>
				</el-tab-pane>
				<el-tab-pane label="延期记录" name="delay">
					<yi-table
						table-height="400px"
						:loading="tableLoading"
						:columns="delayColumns"
						:data="delayData"
					>
						<template #delayAnnexesArr="{ value, row }">
							<el-link
								type="primary"
								:underline="false"
								:disabled="!value || value.length === 0"
								@click="onViewDelayAnnexes(value)"
							>
								查看
							</el-link>
						</template>
					</yi-table>
				</el-tab-pane>
			</el-tabs>
			<div v-if="visible && !['log', 'delay'].includes(activeTab)" v-loading="isLoading">
				<yi-form
					v-if="visible"
					:model="model"
					:rules="rules"
					ref="baseFormRef"
					label-width="120px"
					size="small"
					:form-list="baseForm"
				>
					<template #companyId>
						<el-select
							:disabled="disabled"
							size="default"
							v-model="model.companyId"
							@change="onCompanyChange"
							style="width: 190px"
						>
							<el-option
								v-for="item in companyList"
								:key="item.value"
								:label="item.label"
								:value="item.value"
							/>
						</el-select>
					</template>
					<template #orderCustomerType>
						<el-radio-group
							:disabled="disabled"
							v-model="model.orderCustomerType"
							@change="onRadioChange"
						>
							<el-radio v-for="item in customerType" :key="item.value" :label="item.value">
								{{ item.name }}
							</el-radio>
						</el-radio-group>
					</template>
					<template #driverId>
						<el-select-v2
							placeholder="请选择司机"
							:options="driverOptions"
							filterable
							size="default"
							v-if="title == '新增合同'"
							v-model="model.driverId"
							style="width: 190px"
						>
							<template #default="{ item }">
								<div class="custom-option">
									<span>{{ item.label }}</span>
									<span>{{ item.mobile }}</span>
								</div>
							</template>
						</el-select-v2>
						<el-input
							v-else
							style="width: 190px"
							size="default"
							v-model="model.driverName"
							disabled
						/>
					</template>
					<template #carId>
						<el-select-v2
							placeholder="请选择车牌号"
							:options="licensePlateOptions"
							filterable
							size="default"
							:disabled="disabled"
							v-model="model.carId"
							style="width: 190px"
						>
							<template #default="{ item }">
								<div class="custom-option">
									<span>{{ item.label }}</span>
									<span>{{ item.statusName }}</span>
								</div>
							</template>
						</el-select-v2>
					</template>
					<!-- <template #marketingPlan>
						<el-input
							v-if="!disabled || (disabled && !model.marketingPlan)"
							v-model="model.marketingPlan"
							size="default"
							placeholder="请输入营销方案"
							maxLength="20"
							style="width: 190px"
							:disabled="disabled"
						/>
						<el-tooltip
							v-if="disabled && model.marketingPlan"
							class="box-item"
							effect="dark"
							:content="model.marketingPlan"
							placement="top"
						>
							<el-input
								v-model="model.marketingPlan"
								size="default"
								placeholder="请输入营销方案"
								maxLength="20"
								style="width: 190px"
								:disabled="disabled"
							/>
						</el-tooltip>
					</template> -->
				</yi-form>
				<yi-form
					v-if="visible"
					:model="model"
					:rules="rules"
					ref="formRef"
					label-width="120px"
					size="small"
					:form-list="contractFormList"
				>
					<template #contractStartDate>
						<el-date-picker
							:disabled="disabledEditStartDate || disabled"
							type="date"
							style="width: 190px"
							size="default"
							placeholder="选择开始时间"
							value-format="YYYY-MM-DD"
							:disabled-date="disabledDate"
							@update:modelValue="onDateChange"
							v-model="model.contractStartDate"
						/>
					</template>
					<template #contractEndDate>
						<el-date-picker
							:disabled="true"
							placeholder=""
							type="date"
							style="width: 190px"
							size="default"
							value-format="YYYY-MM-DD"
							v-model="model.contractEndDate"
						/>
					</template>
					<template #contractPeriod>
						<el-select
							style="width: 190px"
							v-if="useProduct"
							:disabled="disabled"
							v-model="model.contractPeriod"
							value-key="id"
							@change="onPeriodChange"
							size="default"
							placeholder="请选择合同期数"
						>
							<el-option
								v-for="item in periodList"
								:key="item.value"
								:label="item.label"
								:value="item.value"
							></el-option>
						</el-select>
						<el-input
							style="width: 190px"
							v-else
							size="default"
							:disabled="disabled"
							placeholder="请输入合同期数"
							@change="val => onPeriodChange(val, false)"
							v-model="model.contractPeriod"
						></el-input>
					</template>
					<template #monthlyRent>
						<el-input
							style="width: 190px"
							size="default"
							:disabled="disabled || monthlyDis"
							placeholder="请输入合同月租"
							maxLength="5"
							v-model="model.monthlyRent"
						></el-input>
					</template>
					<template #drivingLicenseDate>
						<el-date-picker
							:disabled="disabled"
							type="daterange"
							size="default"
							style="width: 190px"
							v-model="model.drivingLicenseDate"
						/>
					</template>
					s
				</yi-form>
				<div style="margin-left: 38px; width: 100%">
					<el-checkbox
						style="margin-left: 12px"
						v-model="hasExtra"
						:disabled="disabled"
						@change="onCheckChange"
						label="合同有已收押金"
					/>
					<el-form :model="model" v-if="hasExtra" :rules="rules" ref="extraAmountRef">
						<div class="extra-deposit">
							<p style="font-size: 12px; height: 40px; font-weight: 600; margin-right: 11px">
								当期已收押金
							</p>
							<el-form-item>
								<el-date-picker
									:disabled="true"
									style="width: 190px"
									v-model="model.extraSecurityDeposit.date"
								/>
							</el-form-item>
							<el-form-item prop="extraSecurityDeposit.amount" style="margin-left: 118px">
								<el-input
									:disabled="disabled"
									placeholder="请输入已收押金金额"
									style="width: 192px"
									v-model="model.extraSecurityDeposit.amount"
								/>
							</el-form-item>
						</div>
					</el-form>
				</div>
				<el-form :model="formData" :rules="rules" ref="tableRef">
					<el-table
						border
						style="margin: 20px 0; width: 570px; margin-left: 50px"
						:data="formData.tableData"
						:columns="columns"
					>
						<el-table-column prop="no" label="押金期数" width="100"></el-table-column>
						<el-table-column width="240" label="还款日">
							<template #default="scope">
								<el-form-item
									width="120"
									:prop="`tableData.${scope.$index}.date`"
									:rules="rules.date"
								>
									<el-date-picker
										:disabled="disabled || scope.$index > 0"
										type="date"
										style="width: 190px; margin-top: 20px"
										size="default"
										placeholder="请选择押金还款日"
										:disabled-date="disabledBillDate"
										value-format="YYYY-MM-DD HH:mm:ss"
										v-model="scope.row.date"
										@change="onBillDateChange"
									/>
								</el-form-item>
							</template>
						</el-table-column>
						<el-table-column label="账单金额">
							<template #default="scope">
								<el-form-item
									width="120"
									:prop="`tableData.${scope.$index}.amount`"
									:rules="rules.amount"
								>
									<el-input
										:disabled="(formData.tableData.length === 1 && !hasExtra) || disabled"
										maxLength="7"
										placeholder="请输入本期押金金额"
										v-model="scope.row.amount"
										style="width: 100%; margin-top: 20px"
									></el-input>
								</el-form-item>
							</template>
						</el-table-column>
					</el-table>
				</el-form>
				<div style="margin-left: 50px">
					<p class="label" v-if="policyText">政策说明</p>
					<p>{{ policyText }}</p>
					<p class="label">合同备注</p>
					<el-input
						:disabled="title === '合同详情'"
						type="textarea"
						v-model="model.contractRemark"
						maxLength="200"
						style="width: 570px; margin-bottom: 20px"
						:autosize="{ minRows: 4 }"
					/>
					<p class="title">
						合同文件
						<span>({{ model.contractAnnexesArr ? model.contractAnnexesArr.length : 0 }}/15)</span>
					</p>
					<yi-file-uploader
						v-model="model.contractAnnexesArr"
						type="file"
						source-type="image"
						product-type="renrenjia"
						accept=".jpg,.jpeg,.png,.xlsx,.xls,.pdf,.zip,.rar,.pptx,.docx"
						:max="15"
						:disabled="title === '合同详情'"
					/>
				</div>
			</div>

			<template #footer>
				<div class="dialog-footer">
					<span class="button-item" v-if="title !== '合同详情'">
						<el-button type="primary" @click="onSubmit">确 定</el-button>
					</span>
					<span class="button-item" v-if="title === '合同详情' && activeTab === 'form'">
						<el-button
							type="danger"
							v-permission="'pc/driver-center/contract-manage/invalid'"
							@click="onInvalid"
						>
							作 废
						</el-button>
					</span>
					<span class="button-item"><el-button @click="onClose">关 闭</el-button></span>
				</div>
			</template>
		</el-dialog>
		<el-dialog
			v-model="isShowDialog"
			width="600px"
			class="dialog-container"
			title="凭证附件"
			:before-close="onCloseDialog"
			:close-on-click-modal="false"
			append-to-body
			destroy-on-close
		>
			<div class="dialog-body">
				<div class="dialog-list">
					<div
						v-for="(item, index) in delayAnnexesArray"
						class="list-item"
						style="margin-bottom: 10px"
						:key="index"
					>
						<el-link type="primary" :underline="false" @click="onClickDelayAnnexes(item)">
							{{ item.name }}
						</el-link>
					</div>
				</div>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button :disabled="isDisabled" @click="onCloseDialog">取 消</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script setup>
import dayjs from 'dayjs'
import { defineExpose, ref, inject, defineEmits, watch, computed } from 'vue'
import { useState, useModal, useFetch, useSelect, useDict } from '@/utils/hooks.js'
import {
	companySelect,
	carSelectList,
	maintenanceSelect,
	operationRecords,
	delayRecords,
} from '@/api/vehicle'
import {
	createOrder,
	editOrder,
	driverSelect,
	getChannelByCar,
	contractDetail,
	contractInvalid,
	getCompanyById,
} from '@/api/driver'
import { productList } from '@/api/product'
import { form, logColumns, contractForm, delayColumns } from './config'
import { status } from '../../vehicle/enums'
import { usePermissionStore } from '@/store/modules/permission'
const permissionStore = usePermissionStore()

const $message = inject('$message')
const emit = defineEmits(['on-refresh'])
const tableRef = ref(null)
const baseFormRef = ref(null)
const extraAmountRef = ref(null)
const baseForm = ref(form)
const hasExtra = ref(true)
const contractFormList = ref(contractForm)
const formList = ref([...form, ...contractForm])
const columns = []
const { visible, title, setVisible, setTitle } = useModal()
let delayAnnexesArray = ref([])
const isShowDialog = ref(false)

const [companyList, setCompany] = useState([])
const [model, setModel] = useState({
	extraSecurityDeposit: {},
	contractStartDate: dayjs().format('YYYY-MM-DD'),
})
const [activeTab, setTab] = useState('form')
const [logData, setLogData] = useState([])
const [delayData, setDelayData] = useState([])
const [tableLoading, setLoading] = useState(false)
const [disabled, setDisabled] = useState(false)
const [licensePlateOptions, setOptions] = useState([])
const [orderId, setOrderId] = useState('')
const [channel, setChannel] = useState([])
const { isLoading, fetchData } = useFetch()
const [periodList, setPeriod] = useState([])
const [config, setConf] = useState({})
const [tableData, setTable] = useState([{ no: '第1期押金' }])
const [customerType, setCustomerType] = useState([])
const [formData, setForm] = useState({ tableData: tableData.value })
const [driverOptions, setDriverOptions] = useState([])
const [products, setProduct] = useState([])
const [monthlyDis, setMonthlyDis] = useState(false)
const [useProduct, setUseProduct] = useState(true)
const [policyText, setPolicy] = useState('')
// 是否有编辑合同开始日期的权限
const disabledEditStartDate = computed(() => {
	return (
		permissionStore.authList.findIndex(
			p => p.code === 'pc/driver-center/contract-manage/edit-start',
		) < 0
	)
})
watch(
	() => model.value.carId,
	(carId, oldVal) => {
		if (carId !== oldVal && oldVal && title.value !== '合同详情') {
			model.value.platformChannelId = ''
			clearOptions()
		}
		const target = licensePlateOptions.value.find(item => item.id == carId)
		console.log('选中的车型', target)
		if (target) model.value.carModelId = target.carModelId
		fetchChannel(carId)
		fetchProduct()
	},
)
watch(
	() => model.value.platformChannelId,
	() => {
		fetchProduct()
	},
)

watch(
	() => model.value.goodsLeaseId,
	(val, oldVal) => {
		const target = products.value.find(item => item.value == val)
		const { leaseSchemeList } = target || {}
		if (val && oldVal) {
			model.value.contractPeriod = ''
			model.value.monthlyRent = ''
			model.value.securityDeposit = ''
			setPolicy('')
			clearOptions()
		}

		if (leaseSchemeList) {
			const list = genList(leaseSchemeList, 'periodsNum')
			setPeriod(list.sort((a, b) => a.value - b.value))
		}
	},
)
function clearOptions() {
	model.value.monthlyRent = ''
	model.value.securityDeposit = ''
	model.value.contractPeriod = ''
	model.value.contractPeriodObj = ''
	setPeriod([])
}
function genList(list, key) {
	return list.reduce((acc, cur) => {
		const { monthlyAmount, cashPledgeAmount } = cur
		const label = `${cur[key]}期(月租：${monthlyAmount}元，押金：${cashPledgeAmount}元)`
		const res = { ...cur, label, value: { value: cur[key], id: cur.id } }
		acc.push(res)
		return acc
	}, [])
}

watch(
	() => model.value.securityDeposit,
	val => {
		if (tableData.value.length === 1 && !hasExtra.value) {
			tableData.value[0].amount = val ? val : undefined
		}
	},
)
watch(
	() => model.value.orderSource,
	val => {
		const recommendMobile = findItem('recommendMobile')
		const orderSourceChannel = findItem('orderSourceChannel')
		const internetPlatform = findItem('internetPlatform')
		const salesmanId = findItem('salesmanId')
		//朋友推荐/司机推荐
		recommendMobile.hide = ![1, 3].includes(val)
		//渠道收口
		orderSourceChannel.hide = val != 2
		//网络平台
		internetPlatform.hide = val != 5
		salesmanId.hide = val != 4
	},
)
watch(
	() => model.value.platformChannelId,
	(val, oldVal) => {
		if (val && channel.value) {
			const target = channel.value.find(item => item.id === val)
			if (target) {
				const { billGenerateType, billPeriodType } = target
				model.value.monthlyRentGenerateType = billGenerateType

				setConf({ billGenerateType, billPeriodType })
				if (billPeriodType === 1) {
					model.value.dueDate = 31
				} else {
					model.value.dueDate = model.value.dueDate ? model.value.dueDate : undefined
				}
				const dueDate = findItem('dueDate')
				dueDate.attrs.disabled = billPeriodType === 1 || title.value !== '新增合同'
				if (model.value.contractStartDate) {
					onRelativeChange(model.value.dueDate)
				}
			}
			const type = findItem('monthlyRentGenerateType')
			type.attrs.disabled = (target && target.billGenerateType) || title.value !== '新增合同'
		}
		if (val && oldVal && val !== oldVal) {
			clearOptions()
		}
	},
)
watch(
	() => model.value.depositPeriod,
	val => {
		const len = tableData.value.length
		if (val < len) {
			tableData.value = tableData.value.slice(0, val)
		} else if (val > len) {
			for (let i = len; i < val; i++) {
				const cur = i === 0 ? undefined : tableData.value[i - 1].date
				const date = cur ? getNextDate(cur) : undefined
				tableData.value.push({
					no: `第${i + 1}期押金`,
					date,
					amount: '',
				})
			}
		}
		if (tableData.value.length === 1 && model.value.securityDeposit) {
			tableData.value.amount = model.value.securityDeposit
		}
		if (tableData.value.length === 1 && !hasExtra.value) {
			tableData.value[0].amount = model.value.securityDeposit
				? model.value.securityDeposit
				: undefined
		}
		model.value.securityDeposit =
			val === 0 && title.value === '新增合同' && !useProduct.value ? 0 : model.value.securityDeposit
		const securityDeposit = findItem('securityDeposit')
		securityDeposit.attrs.disabled =
			useProduct.value || (val === 0 && !hasExtra.value) || title.value !== '新增合同'
		calcDate()
		setForm({ tableData: tableData.value })
	},
)

const rules = {
	monthlyRent: [
		{ required: true, message: '合同月租不能为空' },
		{ pattern: /^[0-9]*$/, message: '合同月租必须是整数', trigger: 'blur' },
	],
	securityDeposit: [
		{ pattern: /^\d+(?:\.\d{1,2})?$/, message: '合同押金格式有误', trigger: 'blur' },
	],
	amount: [
		{ required: true, message: '请填写账单金额' },
		{ pattern: /^\d+(?:\.\d{1,2})?$/, message: '账单金额格式有误', trigger: 'blur' },
	],
	'extraSecurityDeposit.amount': [
		{ required: true, message: '请填写已收押金金额' },
		{ pattern: /^\d+(?:\.\d{1,2})?$/, message: '已收押金金额格式有误', trigger: 'blur' },
	],
	recommendMobile: [
		{ pattern: /^1[0-9]{10}$/, trigger: 'blur', message: '请输入正确格式的手机号' },
	],
}
const formRef = ref(null)

function getNextDate(date) {
	const day = dayjs(date).$D
	const lastDay = dayjs(date).endOf('month').$D
	const nextDate = dayjs(date).add(1, 'month')
	const res = day === lastDay ? nextDate.endOf('month') : nextDate
	return res
}
function calcDate() {
	if (tableData.value.length < 1) return
	const type = model.value.monthlyRentGenerateType
	const dueDate = model.value.dueDate
	const start = model.value.contractStartDate
	const cur = dayjs(start)
	if (type * 1 === 1) {
		const lastDay = dayjs(cur).endOf('month').$D
		const day = dueDate > lastDay ? lastDay : dueDate
		tableData.value[0].date = dayjs(cur.format('YYYY-MM') + `-${day} 00:00:00`)
	} else if (type * 1 === 2) {
		const curLastDay = dayjs(cur).endOf('month').$D
		const curDueDate = dueDate > curLastDay ? curLastDay : dueDate
		const nextDate = dayjs(cur.format('YYYY-MM') + `-${curDueDate} 00:00:00`).add(1, 'month')
		const nextMonth = nextDate.get('month')
		const nextLastDay = nextDate.endOf('month').$D
		const lastDay = dueDate === 31 || (nextMonth === 1 && dueDate > 28) || dueDate >= nextLastDay
		const day = dueDate ? dueDate : dayjs(nextDate).endOf('month').$D
		const resDate = lastDay
			? nextDate.endOf('month')
			: nextDate.format('YYYY-MM') + `-${day} 00:00:00`
		tableData.value[0].date = dayjs(resDate)
	}
	if (model.value.contractStartDate) {
		onBillDateChange()
	}
}
function onCheckChange(val) {
	const securityDeposit = findItem('securityDeposit')
	securityDeposit.attrs.disabled = useProduct.value || (!val && model.value.depositPeriod == 0)
	if (tableData.value.length === 1 && !val) {
		tableData.value[0].amount = model.value.securityDeposit
			? model.value.securityDeposit
			: undefined
	}
}
function onRelativeChange(val) {
	if (val) {
		const date = model.value.dueDate
		if (date) calcDate()
	}
}
function onBillDateChange() {
	tableData.value.forEach((item, index) => {
		if (index > 0) {
			const cur = tableData.value[index - 1].date
			const date = getNextDate(cur)
			item.date = date
		}
	})
}
function onDateChange(date) {
	model.value.contractStartDate = date
	if (!date) {
		model.value.dueDate = undefined
		model.value.contractEndDate = undefined
		return
	}
	const { billPeriodType } = config.value
	if (billPeriodType !== 1) model.value.dueDate = dayjs(date).get('date')
	calcDate()
	const { contractPeriod } = model.value
	const end = dayjs(date).add(contractPeriod, 'month')
	model.value.contractEndDate = end.format('YYYY-MM-DD')
}
function disabledDate(time) {
	return time.getTime() > new Date(dayjs(new Date()).format('YYYY-MM-DD') + ' 00:00:00').getTime()
}
function disabledBillDate(time) {
	const cur = model.value.contractStartDate
	const curYear = dayjs(cur).get('year')
	const curMonth = dayjs(cur).get('month')
	const pickMonth = dayjs(time).get('month')
	const pickYear = dayjs(time).get('year')
	if (cur) {
		if (time.getTime() < new Date(dayjs(cur).add(1, 'day')).getTime()) return true
		if (curYear !== pickYear) {
			if (curMonth === 11 && pickMonth === 0 && pickYear > curYear && pickYear - curYear === 1)
				return false
			return true
		} else {
			if (curMonth === 11 && pickMonth === 0 && pickYear - curYear !== 1) return true
		}
		if (curMonth === 11) return curMonth - pickMonth !== 0 && pickMonth !== 0
		return ![0, -1].includes(curMonth - pickMonth)
	}
}
function onTabChange(tab) {
	setTab(tab.paneName.value)
}
function onPeriodChange(val, hasProduct = true) {
	const value = typeof val === 'object' ? val.value : val
	if (Number.isNaN(Number(value))) {
		$message.error('合同期数请输入数字')
		return
	}
	const { contractStartDate } = model.value
	const end = dayjs(contractStartDate).add(value, 'month')
	model.value.contractEndDate = end.format('YYYY-MM-DD')
	const securityDeposit = findItem('securityDeposit')

	securityDeposit.attrs.disabled = !!hasProduct
	if (!hasProduct) {
		return setPolicy('')
	}
	const target = periodList.value.find(item => item.value == val)
	const {
		id,
		monthlyAmount,
		cashPledgeAmount,
		companyWelfarePolicy,
		platformWelfarePolicy,
		giftDays,
		periodsNum,
		children,
	} = target
	model.value.monthlyRent = monthlyAmount
	model.value.contractPeriod = periodsNum
	model.value.securityDeposit = cashPledgeAmount
	setMonthlyDis(!children)
	model.value.leasingSchemeId = id
	const day = giftDays ? `期满赠送${giftDays}天` : ''
	const policy1 = giftDays
		? `${platformWelfarePolicy ? '，' + platformWelfarePolicy : ''}`
		: platformWelfarePolicy || ''
	const policy2 = policy1
		? `，${companyWelfarePolicy.map(txt => txt).join('，')}`
		: companyWelfarePolicy.map(txt => txt).join('，')
	const text = day + policy1 + policy2
	setPolicy(text)
}
function onRadioChange(val) {
	const { companyId } = model.value
	const company = formList.value.find(item => item.label === '公司名称')
	const driver = formList.value.find(item => item.label === '司机姓名')
	company.hide = val === 1
	driver.hide = val === 2
	model.value.driverId = undefined
	if (val === 1 && companyId) {
		fetchDrivers()
	}
	if (val === 2 && companyId) {
		fetchCompanyList()
	}
}
async function fetchProduct() {
	if (title.value !== '编辑合同') model.value.goodsLeaseId = ''
	const payload = { leaseSchemeStatus: [1], leaseSchemeType: 1 }
	const { platformChannelId, carModelId, companyId } = model.value
	if (!platformChannelId || !carModelId || !companyId) return
	if (platformChannelId) payload.platformChannelIds = [platformChannelId]
	if (carModelId) payload.carModelIds = [carModelId]
	if (companyId) payload.companyIds = [companyId]

	const { data, err } = await fetchData(productList, payload)
	if (!err) console.log('商品方案列表', data)

	const { records } = data

	const list = records.map(item => ({
		...item,
		label: item.leaseSchemeName,
		value: item.id,
	}))
	setProduct(list)
	const goodsLeaseId = findItem('goodsLeaseId')
	goodsLeaseId.options = list
	goodsLeaseId.attrs.placeholder = records.length ? '请选择商品方案' : '当前车辆无商品方案'
	if (formRef.value) formRef.value.clearValidate()
	if (records && records.length == 1 && title.value !== '编辑合同') {
		model.value.goodsLeaseId = records[0].id
	}
}
async function onCompanyChange(val, isInit) {
	const { orderCustomerType } = model.value
	if (orderCustomerType === 1) {
		fetchDrivers()
	}
	if (orderCustomerType === 2) {
		fetchCompanyList()
	}
	fetchDriverBuddy(val)
	fetchSales(val)
	getCarNo([val])
	const target = companyList.value.find(item => item.companyId == val)
	if (target) {
		const { useContractProduct } = target
		setUseProduct(useContractProduct)
		const goodsLeaseId = findItem('goodsLeaseId')
		const leasingSchemeName = findItem('leasingSchemeName')
		goodsLeaseId.hide = !useContractProduct
		leasingSchemeName.hide = useContractProduct
	}
	if (!isInit) {
		model.value.platformChannelId = ''
		model.value.carNumber = ''
		model.value.driverSupportId = ''
		model.value.carId = undefined
		model.value.driverId = undefined
		model.value.goodsLeaseId = ''
		model.value.contractPeriod = ''
		clearOptions()
	}
	onPeriodChange('', false)
	setModel(Object.assign(model.value))
	fetchProduct()
}
function onViewDelayAnnexes(data) {
	const delayAnnexesArr = []

	data.forEach(item => {
		const delayAnnexes = {}
		const lastIndex = item.lastIndexOf('/')
		const fileName = item.substr(lastIndex + 1)
		delayAnnexes.name = fileName
		delayAnnexes.path = item
		delayAnnexesArr.push(delayAnnexes)
	})

	delayAnnexesArray.value = delayAnnexesArr
	isShowDialog.value = true
}
function onClickDelayAnnexes(row) {
	window.open(row.path)
}
function onCloseDialog() {
	isShowDialog.value = false
}
function open(data, type) {
	fetchList()
	const recommendMobile = findItem('recommendMobile')
	const orderSourceChannel = findItem('orderSourceChannel')
	const internetPlatform = findItem('internetPlatform')
	const salesmanId = findItem('salesmanId')
	const penaltyProportion = findItem('penaltyProportion')
	const giftDays = findItem('giftDays')
	const goodsLeaseId = findItem('goodsLeaseId')
	const schemeName = findItem('leasingSchemeName')

	recommendMobile.required = !data
	orderSourceChannel.required = !data
	internetPlatform.required = !data
	salesmanId.required = !data
	penaltyProportion.hide = !data
	giftDays.hide = !data
	if (data) {
		const { orderId } = data
		setOrderId(data.orderId)
		onCompanyChange(data.companyId, true)
		if (type === 'detail') {
			getDetail(orderId)
			setDisabled(true)
			formList.value.forEach(item => {
				if (item.attrs) item.attrs.disabled = true
				else {
					item.attrs = {}
					item.attrs.disabled = true
				}
			})
			setTitle('合同详情')
			setVisible(true)
			return
		}
		goodsLeaseId.hide = true
		schemeName.hide = false
		setTitle('编辑合同')
		getDetail(orderId)
		setDisabled(true)
	} else {
		goodsLeaseId.hide = false
		schemeName.hide = true
		setTitle('新增合同')
		setDisabled(false)
		model.value.depositPeriod = 1
		model.value.orderCustomerType = 1
		model.value.extraSecurityDeposit.date = dayjs().format('YYYY-MM-DD HH:mm:ss')
		driverOptions.value = []
	}
	const valid = ['driverSupportId']
	const dueDate = findItem('dueDate')
	const generateType = findItem('monthlyRentGenerateType')
	dueDate.on = { change: onRelativeChange }
	generateType.on = { change: onRelativeChange }
	formList.value.forEach(item => {
		if (item.attrs) item.attrs.disabled = data && !valid.find(key => key === item.prop)
		else {
			item.attrs = {}
			item.attrs.disabled = data && !valid.find(key => key === item.prop)
		}
		if (item.prop === 'orderNo') item.attrs.disabled = true
	})
	setVisible(true)
}
async function getLog(id) {
	setLoading(true)
	const { data, err } = await fetchData(operationRecords, {
		logKey: id,
		page: { current: 1, size: 99 },
	})
	setLoading(false)
	if (err) return $message.error(err.message)
	const logData = data.records.map(item => JSON.parse(item.logContent))
	setLogData(logData)
}
async function getDelay(id) {
	setLoading(true)
	const { data, err } = await fetchData(delayRecords, id)
	setLoading(false)
	if (err) return $message.error(err.message)
	const delayData = data
	setDelayData(delayData)
}
async function getDetail(id) {
	getLog(id)
	getDelay(id)
	const { data, err } = await fetchData(contractDetail, id)
	if (err) return $message.error(err.message)
	const {
		depositPeriod,
		securityDepositDetails,
		dueDate,
		orderCustomerType,
		extraSecurityDeposit,
		welfarePolicy,
		orderLeasingScheme,
		driverName,
		driverId,
	} = data

	const { penaltyProportion, giftDays } = orderLeasingScheme || {}
	hasExtra.value = !!extraSecurityDeposit
	const { amount, date } = extraSecurityDeposit || {}

	onRadioChange(orderCustomerType || 1)
	setModel(
		Object.assign(data, {
			orderSource: data.orderSource * 1,
			depositPeriod: depositPeriod || 0,
			extraSecurityDeposit: { amount, date: new Date(date) },
			penaltyProportion: penaltyProportion ? `${penaltyProportion}%` : '',
			giftDays: giftDays ? giftDays + '天' : '',
			driverId: driverName || driverId,
			driverName: driverId || driverName,
		}),
	)
	if (securityDepositDetails) {
		setTable(
			securityDepositDetails.map((item, i) => ({
				...item,
				no: `第${i + 1}期押金`,
				date: dayjs(item.date).format('YYYY-MM-DD HH:mm:ss'),
			})),
		)
	} else {
		onRelativeChange(dueDate)
	}
	if (welfarePolicy) setPolicy(welfarePolicy)
	setForm({ tableData: tableData.value })
}
function onClose() {
	clearOptions()
	setVisible(false)
	setModel({
		extraSecurityDeposit: {},
		contractStartDate: dayjs().format('YYYY-MM-DD'),
	})
	hasExtra.value = true
	onRadioChange(1)
	setOptions([])
	const goodsLeaseId = findItem('goodsLeaseId')
	goodsLeaseId.options = []
	setTab('form')
	setTable([{ no: '第1期押金' }])
	setForm({ tableData: tableData.value })
}
async function onSubmit() {
	await Promise.all([
		formRef.value.validate(),
		tableRef.value.validate(),
		baseFormRef.value.validate(),
		extraAmountRef.value && extraAmountRef.value.validate(),
	])

	try {
		const params = { ...model.value }
		const { extraSecurityDeposit } = params
		params.extraSecurityDeposit = hasExtra.value ? extraSecurityDeposit : null
		const securityDepositDetails = tableData.value.map(item => {
			const { date, amount } = item
			return { amount, date: dayjs(date).format('YYYY-MM-DD HH:mm:ss') }
		})
		params.securityDepositDetails = securityDepositDetails
		const { billPeriodType } = config.value
		params.monthlyRentPeriodType = billPeriodType
		const { contractEndDate, contractStartDate } = params
		params.contractEndDate = dayjs(contractEndDate).format('YYYY-MM-DD') + ' 23:59:59'
		params.contractStartDate = dayjs(contractStartDate).format('YYYY-MM-DD') + ' 00:00:00'
		const service = title.value.includes('编辑') ? editOrder : createOrder
		const res = await service(params)
		if (res.code !== 200) throw new Error(res.msg || res.message)
		$message.success(title.value.includes('编辑') ? '编辑成功' : '新建成功')
		emit('on-refresh')
		onClose()
	} catch (e) {
		$message.error(e.message)
	}
}
async function onInvalid() {
	const { err } = await fetchData(contractInvalid, { orderId: orderId.value })
	if (err) return $message.error(err.message)
	$message.success('作废成功')
	emit('on-refresh')
	onClose()
}
function findItem(key, component) {
	if (component)
		return formList.value.find(item => item.prop === key && component == item.component)
	return formList.value.find(item => item.prop === key)
}
async function fetchDrivers() {
	const fn = () =>
		driverSelect({
			companyId: model.value.companyId,
			status: [1, 3, 4],
		})
	const { data } = await useSelect(fn, { name: 'name', code: 'driverId' })
	setDriverOptions(data)
}
async function fetchCompanyList() {
	const company = formList.value.find(item => item.label === '公司名称')
	const fn = () =>
		getCompanyById({
			companyId: model.value.companyId,
			status: 1,
		})
	const { data } = await useSelect(fn, { name: 'companyName', code: 'companyCustomerId' })
	if (company) company.options = data
}
async function fetchChannel(id) {
	const fn = () => getChannelByCar(id)
	const { data, all } = await useSelect(fn)
	const platformChannel = findItem('platformChannelId')
	platformChannel.options = data
	setChannel(all)
	if (data.length === 1) model.value.platformChannelId = data[0].value
}
async function fetchDriverBuddy(id) {
	const fn = () => maintenanceSelect('DriverService', [id], { isAll: false })
	const { data, err } = await useSelect(fn, { name: 'realName', code: 'id' })

	if (!err) {
		const driverSupportId = findItem('driverSupportId')
		driverSupportId.options = data
		if (data.length === 1) {
			model.value.driverSupportId = data[0].value
		}
	}
}
async function fetchSales(id) {
	const fn = () => maintenanceSelect('Sale', [id], { isAll: true })
	const { data, err } = await useSelect(fn, { name: 'realName', code: 'id' })

	if (!err) {
		const salesmanId = findItem('salesmanId')
		salesmanId.options = data
		if (data.length === 1) {
			model.value.salesmanId = data[0].value
		}
	}
}
function mapStatus(value) {
	const target = status.find(item => item.code == value)
	return target ? target.name : '--'
}
async function getCarNo(campanyIdList) {
	// 后端接收参数命名拼写错误（campany）不要去改
	const { data } = await useSelect(
		() => carSelectList({ statusList: [1, 2, 3, 4, 5, 6, 7, 8], campanyIdList }),
		{
			code: 'id',
			name: 'licensePlateNum',
		},
	)
	if (data) {
		const options = data.map(item => ({
			...item,
			disabled: ![4].includes(item.status),
			statusName: mapStatus(item.status),
			value: item.id,
		}))
		const valid = []
		const invalid = []
		for (const option of options) {
			if ([4].includes(option.status)) valid.push(option)
			else invalid.push(option)
		}
		setOptions(valid.concat(invalid))
	}
}
async function fetchList() {
	const res = await Promise.all([
		useSelect(() => companySelect(true), { name: 'companyName', code: 'companyId' }),
	])
	const [companyRes] = res
	if (!companyRes.err) {
		setCompany(companyRes.data)
		if (companyRes.data.length === 1) {
			const { value } = companyRes.data[0]
			console.log('company', value)
			model.value.companyId = value
			onCompanyChange(value, true)
		}
	}
}
function format(list) {
	return list.map(item => ({
		...item,
		label: item.name,
		value: item.code * 1,
	}))
}
async function fetchDict() {
	const dict = await useDict([
		'contract_period',
		'bill_generate_type',
		'deposit_period',
		'order_customer_type',
		'order_source',
		'internet_platform',
		'order_source_channel',
	])

	const generateType = findItem('monthlyRentGenerateType')
	const depositPeriod = findItem('depositPeriod')
	const orderSource = findItem('orderSource')
	const orderSourceChannel = findItem('orderSourceChannel')
	const internetPlatform = findItem('internetPlatform')
	orderSource.options = format(dict.order_source)
	generateType.options = format(dict.bill_generate_type)
	depositPeriod.options = format(dict.deposit_period)
	internetPlatform.options = format(dict.internet_platform)
	orderSourceChannel.options = format(dict.order_source_channel)
	setCustomerType(format(dict.order_customer_type))
}
fetchDict()
defineExpose({ open })
</script>

<style lang="less">
.el-select-v2__placeholder {
	font-weight: 400 !important;
}
.el-select-dropdown__option-item {
	font-size: 14px !important;
}
</style>

<style lang="less" scoped>
.title {
	border-left: 3px solid #2e3443;
	color: #2e3443;
	padding-left: 4px;
	font-size: 16px;
	font-weight: 500;
}
.img-box {
	.flexible(row,center, flex-start);
}
.label {
	color: #606266;
	font-size: 12px;
	font-weight: 600;
}
.custom-option {
	.flexible(row, center, space-between);
}
.extra-deposit {
	.flexible(row, center, flex-start);
}
.dialog-footer {
	.button-item {
		margin-left: 15px;
	}
}
</style>
